export const getAdvertCardTouchPointButton = ({
    isNearbySection,
    isNew,
    isPromotedSection,
    isRelatedAdsParent,
}: {
    isNearbySection: boolean;
    isNew?: boolean;
    isPromotedSection?: boolean;
    isRelatedAdsParent?: boolean;
}): string => {
    if (isRelatedAdsParent) {
        return 'combined_ads_main';
    }

    if (isPromotedSection && isNew) {
        return 'promoted_ads_new';
    }

    if (isPromotedSection) {
        return 'promoted_ads';
    }

    if (isNearbySection) {
        return 'nearby_ads';
    }

    return 'ads';
};
