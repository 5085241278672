import { getEstateTranslationKeyPrefix } from '@domains/shared/helpers/getEstateTranslationKeyPrefix';
import { getTransactionTranslationKeyPrefix } from '@domains/shared/helpers/getTransactionTranslationKeyPrefix';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationLink } from '@type/location/locationLink';

export const createLocationLabel = ({
    t,
    name,
    estate,
    transaction,
}: {
    t: Translator;
    name: LocationLink['name'];
    estate: LocationLink['estate'];
    transaction: LocationLink['transaction'];
}): string => {
    const estateLabelKey = getEstateTranslationKeyPrefix(estate);
    const transactionLabelKey = getTransactionTranslationKeyPrefix(transaction);

    const estateLabel = t(estateLabelKey);
    const transactionLabel = t(transactionLabelKey);

    return t('frontend.shared.location-seo-links.link-label', {
        estateLabel,
        transactionLabel,
        location: name,
    });
};
