import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import ListViewIcon from '@nexus/lib-react/dist/core/Icon/icons/default/ListView';
import MapView from '@nexus/lib-react/dist/core/Icon/icons/default/MapView';
import type { Dispatch, ForwardRefRenderFunction, JSX, SetStateAction } from 'react';
import React, { forwardRef, useCallback, useContext } from 'react';

import { GoToMapViewButton, PointerEventsButton } from './ChangeViewTypeButton.theme';

type ChangeViewTypeRenderPlace = 'listingPanel' | 'stickyMobileForm' | 'stickyTopbar' | 'mapContainer';

const MAP_BUTTON_COPY = {
    short: 'frontend.search.form.map-button',
    regular: 'frontend.search.listing.see-on-map',
};

interface Props {
    renderPlace: ChangeViewTypeRenderPlace;
    className?: string;
    shouldUseShortCopy?: boolean;
    isDisplayedOnImage?: boolean;
    setCurrentScrollPosition?: Dispatch<SetStateAction<number>>;
    onViewChange: () => void;
}

const ChangeViewTypeBase: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
    {
        renderPlace,
        className,
        shouldUseShortCopy = false,
        isDisplayedOnImage = false,
        setCurrentScrollPosition,
        onViewChange,
    },
    ref,
): JSX.Element | null => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const { viewType, updateViewType } = useContext(ViewTypeContext);

    const handleChangeViewType = useCallback(() => {
        const shouldSaveScrollingPosition = viewType === 'listing' && setCurrentScrollPosition;

        if (shouldSaveScrollingPosition) {
            setCurrentScrollPosition(document.documentElement.scrollTop);
        }

        onViewChange?.();

        updateViewType(`${viewType === 'listing' ? 'map' : 'listing'}`);
    }, [onViewChange, setCurrentScrollPosition, updateViewType, viewType]);

    const seeOnMapLabel = shouldUseShortCopy ? MAP_BUTTON_COPY.short : MAP_BUTTON_COPY.regular;
    const buttonDataCy = `search.map.go-to-list-button-${renderPlace}`;

    if (viewType === 'listing') {
        return (
            <GoToMapViewButton
                ref={ref}
                variant="secondary"
                prefixIcon={MapView}
                dataAttributes={{ cy: buttonDataCy }}
                onClick={handleChangeViewType}
                size={renderPlace === 'stickyTopbar' ? 'small' : undefined}
                className={className}
                isDisplayedOnImage={isDisplayedOnImage}
            >
                <span>{t(seeOnMapLabel)}</span>
            </GoToMapViewButton>
        );
    }

    if (isDesktop) {
        return (
            <PointerEventsButton
                variant="primary"
                prefixIcon={ListViewIcon}
                dataAttributes={{ cy: buttonDataCy }}
                onClick={handleChangeViewType}
                className={className}
            >
                {t('frontend.search.listing.see-on-list')}
            </PointerEventsButton>
        );
    }

    return null;
};

export const ChangeViewTypeButton = forwardRef<HTMLButtonElement, Props>(ChangeViewTypeBase);
