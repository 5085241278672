import { stringCapitalize } from '@domains/shared/helpers/stringCapitalize';
import type { Estate } from '@type/search/filters/estate';
import { ESTATE } from '@type/search/filters/estate';
import type { Transaction } from '@type/search/filters/transaction';
import { TRANSACTION } from '@type/search/filters/transaction';
import type { TransactionVariant } from '@type/search/transactionVariant';
import { TRANSACTION_VARIANT } from '@type/search/transactionVariant';

const ESTATE_MAP: Record<Estate, string> = {
    /* eslint-disable @typescript-eslint/naming-convention -- ESTATE_MAP uses estate type values, which are uppercased */
    ALL: 'all',
    FLAT: 'flat',
    HOUSE: 'house',
    STUDIO_FLAT: 'studioFlat',
    ROOM: 'room',
    TERRAIN: 'terrain',
    COMMERCIAL_PROPERTY: 'commercialProperty',
    HALL: 'hall',
    GARAGE: 'garage',
    INVESTMENT: 'investment',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const getTransactionVariant = ({
    estate,
    transaction,
}: {
    estate: Estate;
    transaction: Transaction;
}): TransactionVariant => {
    let transactionName = transaction;

    if (estate === ESTATE.investment) {
        return TRANSACTION_VARIANT.investment;
    }

    if (estate === ESTATE.room) {
        transactionName = TRANSACTION.rent;
    }

    const name = `${ESTATE_MAP[estate]}${stringCapitalize(transactionName)}` as keyof typeof TRANSACTION_VARIANT;

    return TRANSACTION_VARIANT[name];
};
