import type { NextRouter } from 'next/router';

export const updateQueryParams = (
    queryParams: Record<string, string | number | undefined>,
    router: NextRouter,
): void => {
    router.push(
        {
            query: { ...router.query, ...queryParams },
        },
        undefined,
        { shallow: true },
    );
};

export const updateBounds = (bounds: string, router: NextRouter): void =>
    updateQueryParams({ mapBounds: bounds }, router);
