import type { SortingOptionsProps } from '@domains/shared/components/ListingSorting/helpers/createDropdownOptionsData';

export const STANDARD_SORTING_OPTIONS: SortingOptionsProps = [
    { label: 'frontend.shared.sorting.date-latest', value: 'LATEST', direction: 'DESC' },
    { label: 'frontend.shared.sorting.date-oldest', value: 'LATEST', direction: 'ASC' },
    { label: 'frontend.shared.sorting.price-lowest', value: 'PRICE', direction: 'ASC' },
    { label: 'frontend.shared.sorting.price-highest', value: 'PRICE', direction: 'DESC' },
    { label: 'frontend.shared.sorting.area-smallest', value: 'AREA', direction: 'ASC' },
    { label: 'frontend.shared.sorting.area-highest', value: 'AREA', direction: 'DESC' },
];

export const EXPERIMENTAL_SORTING_OPTIONS: SortingOptionsProps = [
    { label: 'frontend.shared.sorting.best-match', value: 'BEST_MATCH', direction: 'DESC' },
    { label: 'frontend.shared.sorting.date-latest', value: 'DEFAULT', direction: 'DESC' },
    { label: 'frontend.shared.sorting.date-oldest', value: 'DEFAULT', direction: 'ASC' },
    { label: 'frontend.shared.sorting.price-lowest', value: 'PRICE', direction: 'ASC' },
    { label: 'frontend.shared.sorting.price-highest', value: 'PRICE', direction: 'DESC' },
    { label: 'frontend.shared.sorting.area-smallest', value: 'AREA', direction: 'ASC' },
    { label: 'frontend.shared.sorting.area-highest', value: 'AREA', direction: 'DESC' },
];
