import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { State } from './state';

// list-style: All modern browsers - hide default triangle
// &::-webkit-details-marker safari iOS - hide default triangle
export const BaseSummary = styled.summary<{ state: State }>`
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    cursor: pointer;

    &::-webkit-details-marker {
        display: none;
    }
`;

export const BASE_DETAILS_STYLES = css`
    margin: 0;
    padding: 0;
`;

export const BaseDetails = styled.details<{ state: State }>`
    ${BASE_DETAILS_STYLES}
`;
