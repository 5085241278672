import type { Money } from '@type/pricing/money';

export const getDiscountedPrice = (price: number, discountPercentage: number): number =>
    price - price * (discountPercentage / 100);

export const getDiscountedMoney = ({ value, currency }: Money, discountPercentage: number): Money => {
    return {
        value: getDiscountedPrice(value, discountPercentage),
        currency,
    };
};
