import type { FC, PropsWithChildren } from 'react';

import { BaseSummary } from './Collapsible.theme';
import { NexusStyledIcon, StyledChildrenWrapper, StyledWrapper } from './NexusSummaryWithIcon.theme';
import type { SummaryProps } from './props';

export const NexusSummaryWithIcon: FC<PropsWithChildren<SummaryProps>> = ({ children, ...props }) => {
    return (
        <BaseSummary {...props}>
            <StyledWrapper>
                <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
                <NexusStyledIcon state={props.state} size={'icon-small'} />
            </StyledWrapper>
        </BaseSummary>
    );
};
