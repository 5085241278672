import type { SortingChangeHandlerProps } from '../types/handleSorting';

type SortingTrackingData = Record<string, unknown>;

export const getSortingTrackingData = ({
    by,
    direction,
    isSortingButton = false,
    isButtonInPopover = false,
}: SortingChangeHandlerProps): SortingTrackingData => {
    const shouldHiddenTouchPointButton = isSortingButton || isButtonInPopover;

    return {
        touch_point_button: shouldHiddenTouchPointButton ? 'hidden' : `${by}_${direction}`.toLowerCase(),
        order_by: isSortingButton ? null : `${by}:${direction}`.toLowerCase(),
    };
};
