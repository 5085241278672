import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { ListContainer } from '@nexus/lib-react/dist/core/List';

export const StyledListContainer = styled(ListContainer)`
    width: 100%;
    padding: ${theme.space[8]} ${theme.space[16]} ${theme.space[16]};
`;

export const NexusDropdownWrapper = styled.div`
    width: 295px;
`;
