import TagIcon from '@domains/shared/components/AdvertItem/SpecialOfferHeader/assets/tag.svg';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';
import type { CSSProperties } from 'react';

export const Icon = styled(TagIcon)`
    flex-shrink: 0;
    margin-left: 4px;
    fill: ${({ theme }): CSSProperties['fill'] =>
        theme.deprecated.domains.shared.specialOfferHeader.discountHeader.color};
`;

export const Header = styled.header`
    display: flex;
    grid-area: header;
    align-items: center;
    padding: 8px;
    background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
        theme.deprecated.domains.shared.specialOfferHeader.discountHeader.backgroundColor};
`;

export const Title = styled.h3`
    display: inline-block;
    margin: 0;
    margin-left: 12px;
    color: ${({ theme }): CSSProperties['color'] =>
        theme.deprecated.domains.shared.specialOfferHeader.discountHeader.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
`;

export const AdditionalTitlePart = styled(Title)`
    display: inline-block;
    margin-top: 4px;
    font-size: ${SIZE.p5};
    white-space: nowrap;

    @media (min-width: ${BREAKPOINT.md}) {
        margin-top: 2px;
    }
`;

export const DiscountPercentage = styled.b`
    display: inline-block;
`;
