import type { SearchPageVariant } from '@type/search/pageVariant';
import { useRouter } from 'next/router';

interface UrlMapParams {
    mapBounds: string;
    isOnMapView: boolean;
    hasBoundingBox: boolean;
}

export const useUrlMapParams = (): UrlMapParams => {
    const { query } = useRouter();
    const { viewType, mapBounds = '' } = query;

    const isOnMapView = (viewType as SearchPageVariant) === 'map';
    const hasBoundingBox = !!mapBounds;

    return {
        mapBounds: mapBounds as string,
        isOnMapView,
        hasBoundingBox,
    };
};
