import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ComponentProps, JSX } from 'react';
import { useContext } from 'react';

import { Container, Content, H3, Image, Information } from './NoSearchResults.theme';

interface Props extends ComponentProps<typeof Container> {
    description?: string;
    clickableElement?: JSX.Element | null;
}

export const NoSearchResults = ({ description, clickableElement, ...restProps }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { staticAssetsPrefix } = getPublicEnvConfig();
    const { isDesktop } = useContext(RWDContext);

    const defaultDescription = 'frontend.search.no-results.information';

    return (
        <Container {...restProps}>
            {isDesktop ? (
                <Image
                    src={`${staticAssetsPrefix}/images/search/document-magnifying-glass.png`}
                    alt={t('frontend.search.no-results.title')}
                    width="115"
                    height="107"
                    loading="lazy"
                />
            ) : null}
            <Content>
                <H3>{t('frontend.search.no-results.title')}</H3>
                <Information>{t(description ?? defaultDescription)}</Information>
                {clickableElement ?? null}
            </Content>
        </Container>
    );
};
