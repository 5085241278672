import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const StyledParagraph = styled.p`
    margin: 0;
    overflow: hidden;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes.p3};
    text-overflow: ellipsis;
    white-space: nowrap;
`;
