import type { SortingBy } from '@type/sorting/by';
import type { SortingDirection } from '@type/sorting/direction';

export type SortingOptionsProps = SortingVariant[];
export interface SortingVariant {
    direction: SortingDirection;
    label: string;
    value: SortingBy;
}

export const createDropdownOptionsData = (
    allSortingOptions: SortingOptionsProps,
    options: string[],
): SortingVariant[] => {
    return allSortingOptions.filter(({ value }) => options.includes(value));
};
