import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { getEstateTranslationKeyPrefix } from '@domains/shared/helpers/getEstateTranslationKeyPrefix';
import { getTransactionTranslationKeyPrefix } from '@domains/shared/helpers/getTransactionTranslationKeyPrefix';
import { getSearchQueryParam } from '@domains/shared/helpers/searchQuery';
import { stringCapitalize } from '@domains/shared/helpers/stringCapitalize';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationLink } from '@type/location/locationLink';
import { ESTATE } from '@type/search/filters/estate';
import type { SeoEstate } from '@type/search/filters/seoEstate';
import type { Transaction } from '@type/search/filters/transaction';
import { TRANSACTION } from '@type/search/filters/transaction';
import { useMemo } from 'react';

interface Props {
    location: string | null;
    estate: SeoEstate;
    transaction: Transaction;
    areMultipleLocationsSelected: boolean | null;
    shouldDisplayPrimaryMarket: boolean | null;
    locationLabel?: string;
}

export const useGetPropertiesFromPrimaryMarket = ({
    location,
    estate,
    transaction,
    areMultipleLocationsSelected,
    shouldDisplayPrimaryMarket,
    locationLabel,
}: Props): LocationLink[] => {
    const [t] = useTranslations();
    let investmentEstateType: string | null = null;

    if (typeof window !== 'undefined') {
        investmentEstateType = getSearchQueryParam('investmentEstateType');
    }

    return useMemo<LocationLink[]>(
        () =>
            (location &&
                !areMultipleLocationsSelected &&
                shouldDisplayPrimaryMarket &&
                SEARCH_CONFIG.seo?.internalSeoLinks?.propertiesFromPrimaryMarket
                    ?.filter((estateType) => {
                        return investmentEstateType === null
                            ? !(transaction === TRANSACTION.sell && estateType === estate)
                            : estateType !== investmentEstateType;
                    })
                    .map((estateType: string) => {
                        const estateLabelKey = getEstateTranslationKeyPrefix(estateType);
                        const transactionLabelKey = getTransactionTranslationKeyPrefix(TRANSACTION.sell);
                        let name: string;
                        if (estateType === ESTATE.investment) {
                            name = locationLabel
                                ? t(
                                      'frontend.search.location-seo-links.primary-market-investment-for-sale-with-location',
                                      {
                                          location: locationLabel,
                                      },
                                  )
                                : t('frontend.search.location-seo-links.primary-market-investment-for-sale');
                        } else {
                            name = locationLabel
                                ? t(
                                      'frontend.search.location-seo-links.primary-market-link-name-with-location-updated',
                                      {
                                          estateLabel: t(estateLabelKey).toLowerCase(),
                                          transactionLabel: t(transactionLabelKey),
                                          location: locationLabel,
                                      },
                                  )
                                : t('frontend.search.location-seo-links.primary-market-link-name-updated', {
                                      estateLabel: t(estateLabelKey).toLowerCase(),
                                      transactionLabel: t(transactionLabelKey),
                                  });
                        }

                        return {
                            name: stringCapitalize(name, 'partial'),
                            estate: estateType,
                            transaction: TRANSACTION.sell,
                            location: location || '',
                        };
                    })) ||
            [],
        [
            areMultipleLocationsSelected,
            estate,
            investmentEstateType,
            location,
            locationLabel,
            shouldDisplayPrimaryMarket,
            t,
            transaction,
        ],
    );
};
