import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { ENTITY_ICON } from '@lib/styles/partials/entityIcons';
import { theme } from '@lib/styles/themes/nexus';
import type { Theme } from '@lib/styles/themes/type';
import type { CSSProperties } from 'react';

interface RootProps {
    isOverlay: boolean;
    isFullScreenOnMobile: boolean;
}

const getRootMaxHeight = ({ isFullScreenOnMobile, isOverlay }: RootProps): CSSProperties['maxHeight'] => {
    if (isFullScreenOnMobile) {
        return '100%';
    }

    if (isOverlay) {
        return 'auto';
    }

    return '80vh';
};

export const Root = styled.div<RootProps>`
    display: flex;
    position: absolute;
    z-index: ${theme.zIndices.modal};
    top: 50%;
    left: 50%;
    flex: 0 1 100%;
    flex-direction: column;
    width: 95vw;
    min-width: 300px;
    max-width: ${({ isOverlay }): string => (isOverlay ? '95vw' : '800px')};
    height: ${({ isFullScreenOnMobile }): string => (isFullScreenOnMobile ? '100vh' : 'auto')};
    min-height: 200px;
    max-height: ${getRootMaxHeight};
    margin: 0 auto;
    overflow: auto;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    outline: 0;
    background-color: ${({ theme }): string => theme.deprecated.atoms.modal.root.backgroundColor};
    box-shadow: ${({ theme }): string => theme.deprecated.atoms.modal.root.boxShadow};
    color: ${({ theme }): string => theme.deprecated.atoms.modal.root.color};

    @media (min-width: ${BREAKPOINT.xs}) {
        height: auto;
        max-height: ${({ isOverlay }): string => (isOverlay ? 'auto' : '80vh')};
    }

    @media (min-width: ${BREAKPOINT.md}) {
        width: ${({ isOverlay }): string => (isOverlay ? 'auto' : '740px')};
    }
`;

interface BackdropProps {
    isOverlay: boolean;
    hasOverflow: boolean;
}

const getBackdropBackgroundColor = ({
    isOverlay,
    theme,
}: BackdropProps & { theme: Theme }): CSSProperties['backgroundColor'] => {
    return isOverlay
        ? theme.deprecated.atoms.modal.backdropOverlay.backgroundColor
        : theme.deprecated.atoms.modal.backdrop.backgroundColor;
};

export const Backdrop = styled.div<BackdropProps>`
    position: fixed;
    z-index: ${theme.zIndices.modalBackdrop};
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: ${({ hasOverflow }): string => (hasOverflow ? 'auto' : 'hidden')};
    background-color: ${getBackdropBackgroundColor};
`;

export const CloseButton = styled.button`
    display: flex;
    position: absolute;
    z-index: ${theme.zIndices.modal};
    top: 16px;
    right: 16px;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    border: 0;
    border-radius: 25%;
    background: none;
    color: ${({ theme }): string => theme.deprecated.atoms.modal.closeIcon.color};
    cursor: pointer;

    &::after {
        content: ${ENTITY_ICON.closeButton};
        font-size: 20px;
        font-weight: 700;
        line-height: 0.75;
    }
`;
