import { EXPERIMENT } from '@domains/search/consts/experiment';
import { getSortingTrackingData } from '@domains/search/helpers/getSortingTrackingData';
import type { SortingChangeHandler } from '@domains/search/types/handleSorting';
import {
    EXPERIMENTAL_SORTING_OPTIONS,
    STANDARD_SORTING_OPTIONS,
} from '@domains/shared/components/ListingSorting/constants/standardSortingOptions';
import type { SortingOptionsProps } from '@domains/shared/components/ListingSorting/helpers/createDropdownOptionsData';
import type { ButtonsProps } from '@domains/shared/components/ListingSorting/types/button';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useTracking } from '@lib/tracking/useTracking';
import { SORTING_BUTTON_OPTION } from '@type/sorting/buttonOption';
import type { SortingBy } from '@type/sorting/by';
import { EXPERIMENTAL_SORTING_VALUES, SORTING_BY, STANDARD_SORTING_VALUES } from '@type/sorting/by';

export const useSearchListingSortingProps = (): {
    sortingValues: SortingBy[];
    allSortingOptions: SortingOptionsProps;
    buttonsArray: ButtonsProps[];
    handleSortingChange: SortingChangeHandler<void>;
    handleSortButtonChange: SortingChangeHandler<void>;
} => {
    const { sortingValues, allSortingOptions } = useSortingValues();
    const buttonsArray = useButtonsArray();

    const { trackEvent } = useTracking();

    const handleSortingChange: SortingChangeHandler<void> = (props) => {
        trackEvent('sort_order_change', getSortingTrackingData(props));
    };

    const handleSortButtonChange: SortingChangeHandler<void> = (props) => {
        trackEvent('sort_order_click', getSortingTrackingData(props));
    };

    return {
        sortingValues,
        allSortingOptions,
        buttonsArray,
        handleSortingChange,
        handleSortButtonChange,
    };
};

const useSortingValues = (): { sortingValues: SortingBy[]; allSortingOptions: SortingOptionsProps } => {
    const { isVariantEnabled } = useExperiments();

    if (isVariantEnabled(EXPERIMENT.eure27497, 'b')) {
        return {
            sortingValues: EXPERIMENTAL_SORTING_VALUES,
            allSortingOptions: EXPERIMENTAL_SORTING_OPTIONS,
        };
    }

    return {
        sortingValues: STANDARD_SORTING_VALUES,
        allSortingOptions: STANDARD_SORTING_OPTIONS,
    };
};

const useButtonsArray = (): ButtonsProps[] => {
    const { isVariantEnabled } = useExperiments();
    const [t] = useTranslations();

    const popoverTrigger = {
        label: '...',
        value: SORTING_BY.none,
        ariaLabel: t('frontend.shared.sorting.more-options'),
        dataPopoverTrigger: 'data-popover-trigger',
        dataCy: 'search.sorting.ads.label.sort-by-more',
    };

    if (isVariantEnabled(EXPERIMENT.eure27497, 'b')) {
        return [
            {
                label: t('frontend.shared.sorting.best-match'),
                value: SORTING_BUTTON_OPTION.bestMatch,
            },
            {
                label: t('frontend.shared.sorting.latest-asc'),
                value: SORTING_BUTTON_OPTION.default,
                hideOnMobile: true,
            },
            popoverTrigger,
        ];
    }

    return [
        {
            label: t('frontend.shared.sorting.latest-asc'),
            value: SORTING_BUTTON_OPTION.latest,
        },
        {
            label: t('frontend.shared.sorting.price-asc'),
            value: SORTING_BUTTON_OPTION.price,
            hideOnMobile: true,
        },
        popoverTrigger,
    ];
};
