import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Section = styled.section`
    width: 100%;
    padding: ${theme.space[32]} 0;
`;

export const MultiColumnSegment = styled.div`
    display: grid;
    grid-gap: ${theme.space[32]} ${theme.space[16]};
    grid-template-columns: 1fr;
    width: 100%;
    padding: ${theme.space[32]} 0;
    border-top: 1px solid;
    border-color: ${theme.colors.$03_gray};

    &:last-child {
        padding-bottom: 0;
    }

    @media ${theme.mediaQueries.mdMin} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${theme.mediaQueries.lgMin} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
