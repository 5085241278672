import type { FieldsMetadata } from '@type/search/fields';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';

type FieldsMetadataExperimentWithVariant = Exclude<keyof FieldsMetadata[SearchFormUniversalFieldName], 'default'>;

export const getFieldMetadataSetVariantKey = ({
    fieldMetadataSets,
    fieldsMetadataExperimentsVariants,
}: {
    fieldMetadataSets: FieldsMetadata[SearchFormUniversalFieldName];
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
}): keyof FieldsMetadata[SearchFormUniversalFieldName] => {
    if (!fieldMetadataSets) {
        return 'default';
    }

    const fieldMetadataSetVariant = fieldsMetadataExperimentsVariants.find(
        (experimentWithVariant): experimentWithVariant is FieldsMetadataExperimentWithVariant => {
            return experimentWithVariant in fieldMetadataSets;
        },
    );

    return fieldMetadataSetVariant ?? 'default';
};
