import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { TextContainer } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    width: 100%;
`;

export const Link = styled(TextContainer)`
    color: ${theme.colors['text-brand-primary']};
    font-weight: ${theme.fontWeights[400]};
`.withComponent('a');

export const OneColumnListContainer = styled.ul`
    display: grid;
    grid-gap: ${theme.space[16]};
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeights[18]};
    list-style: none;
`;

export const SectionHeading = styled(TextContainer)`
    margin: 0;
    padding-bottom: ${theme.space[16]};
    color: ${theme.colors['text-brand-primary']};
    font-size: ${theme.fontSizes[16]};
    font-weight: ${theme.fontWeights[700]};
`.withComponent('h3');
