import type { Marker } from '@domains/search/types/mapView';
import { MARKER_TYPE } from '@domains/search/types/mapView';

export const getAdsCountFromPin = (pin: Marker): number => {
    // cluster value is ads count
    if (pin.type === MARKER_TYPE.cluster) {
        return !Array.isArray(pin.value) ? pin.value : 0;
    }

    // processed_cluster is array with pin id,, other case it is single pin with one id
    return Array.isArray(pin.value) ? pin.value.length : 1;
};
