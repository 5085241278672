import type { State } from '@domains/shared/components/Collapsible/state';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { Dispatch, JSX, KeyboardEvent, MouseEvent, SetStateAction } from 'react';
import { useCallback } from 'react';

import { CollapseHeader, CollapsibleSection, DescriptionText, DescriptionTitle } from './Description.theme';

interface Props {
    adId: number;
    description: string;
    setIsDescriptionExpanded?: Dispatch<SetStateAction<boolean>>;
}

export const Description = ({ adId, description, setIsDescriptionExpanded }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleSummaryClick = useCallback(
        (_isOpened: boolean, newState: State, event?: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
            //this component is a children of ListingItem and stopPropagation is used to avoid calling two tracking functions (should not call default 'ad_click' trackEvent with "ads" touch_point_button)
            event?.stopPropagation();
            const isExpanded = newState === 'open' || newState === 'opened';

            if (setIsDescriptionExpanded !== undefined) {
                setIsDescriptionExpanded(isExpanded);
            }

            trackEvent('ad_description_click', {
                touch_point_button: isExpanded ? 'expand' : 'collapse',
                ad_id: adId,
            });
        },
        [adId, setIsDescriptionExpanded, trackEvent],
    );

    return (
        <CollapsibleSection
            summary={<CollapseHeader>{t('frontend.search.description.see-ad-description')}</CollapseHeader>}
            SummaryComponent={DescriptionTitle}
            onChange={handleSummaryClick}
            animationDurationInMs={200}
            shouldRenderContentOnSSR
        >
            <DescriptionText>{description}</DescriptionText>
        </CollapsibleSection>
    );
};
