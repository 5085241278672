import type { FC, ReactNode } from 'react';
import { startTransition, useEffect } from 'react';

interface Props {
    children: ReactNode;
    setIsContentReady(value: boolean): void;
}

export const WatchContentLoad: FC<Props> = ({ children, setIsContentReady }) => {
    useEffect(() => {
        startTransition(() => {
            setIsContentReady(true);
        });

        return (): void => {
            startTransition(() => {
                setIsContentReady(false);
            });
        };
    }, [setIsContentReady]);

    return <>{children}</>;
};
