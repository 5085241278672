import type { FeatureGeometry } from '@type/search/location/dataItems';

import { logError } from './logger';

type InitialMapAreaSearchGeometry = string[] | undefined;
type InitialMapAreaReturn = FeatureGeometry | null | undefined;

export const getInitialMapArea = (geometries: InitialMapAreaSearchGeometry): InitialMapAreaReturn => {
    if (!geometries || geometries.length === 0) {
        return null;
    }

    try {
        const parsedFeatureGeometry = JSON.parse(geometries[0]) as FeatureGeometry;

        return parsedFeatureGeometry;
    } catch (error) {
        logError('[getInitialMapArea] error when parsing', { error });
    }
};
