import styled from '@emotion/styled';
import { SIZE } from '@lib/styles/partials/typography';

export const H1 = styled.h1`
    margin: 0;
    color: ${(props): string => props.theme.deprecated.atoms.heading.h1.color};
    font-size: ${SIZE.x8}px;
    font-weight: 400;
    line-height: 1.1;
`;

export const H2 = styled.h2`
    margin: 0;
    color: ${(props): string => props.theme.deprecated.atoms.heading.h2.color};
    font-size: ${SIZE.x7}px;
    font-weight: 400;
    line-height: 1.1;
`;

export const H3 = styled.h3`
    margin: 0;
    color: ${(props): string => props.theme.deprecated.atoms.heading.h3.color};
    font-size: ${SIZE.x6}px;
    font-weight: 400;
    line-height: 1.1;
`;

export const H4 = styled.h4`
    margin: 0;
    color: ${(props): string => props.theme.deprecated.atoms.heading.h4.color};
    font-size: ${SIZE.x5}px;
    font-weight: 400;
    line-height: 1.1;
`;

export const H5 = styled.h5`
    margin: 0;
    color: ${(props): string => props.theme.deprecated.atoms.heading.h5.color};
    font-size: ${SIZE.x4}px;
    font-weight: 400;
    line-height: 1.1;
`;
