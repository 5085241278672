import type { SortingOption } from '@type/sorting/option';

import type { SortingVariant } from './createDropdownOptionsData';

export const getSelectedSortingOption = (
    allSortingVariants: SortingVariant[],
    sortingOption: SortingOption,
): SortingVariant => {
    return (
        allSortingVariants.find(
            ({ direction, value }) => direction === sortingOption.direction && value === sortingOption.by,
        ) || allSortingVariants[0]
    );
};
