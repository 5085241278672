import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { ModalContext } from '@lib/modalContext/ModalContext';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { FC, KeyboardEvent, ReactNode } from 'react';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Backdrop, CloseButton, Root } from './Modal.theme';
import type { ModalVariant } from './types';

export interface Props {
    children?: ReactNode;
    className?: string;
    variant?: ModalVariant;
    hasOverflow?: boolean;
    hasCloseButton?: boolean;
    isVisible?: boolean;
    isFullScreenOnMobile?: boolean;
    onDismiss(isVisible?: boolean): void;
}

export const Modal: FC<Props> = ({
    className,
    children,
    variant = 'dialog',
    hasOverflow = false,
    hasCloseButton = true,
    isVisible,
    isFullScreenOnMobile = false,
    onDismiss,
}) => {
    const [t] = useTranslations();
    const { modalRef: modalRefContainer } = useContext(ModalContext);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Handle side effect of being visible or not by locking scroll for body
        const modal = modalRef.current as HTMLElement;

        if (!modal) {
            return;
        }
        if (isVisible) {
            disableBodyScroll(modal);
        }

        return (): void => {
            enableBodyScroll(modal);
        };
    }, [isVisible]);

    useEffect(() => {
        if (isVisible) {
            modalRef.current?.focus();
        } else {
            modalRef.current?.blur();
        }
    }, [modalRef, isVisible]);

    useClickOutside(modalRef, () => {
        onDismiss(false);
        clearAllBodyScrollLocks();
    });

    const handleClose = useCallback((): void => {
        onDismiss(false);
        clearAllBodyScrollLocks();
    }, [onDismiss]);

    const handleKeyDown = useCallback(
        ({ key }: KeyboardEvent<HTMLDivElement>): void => {
            // Note: We check both - for better browser support.
            const didPressEscapeKey = key === 'Escape' || key === 'Esc';

            if (!didPressEscapeKey || !isVisible) {
                return;
            }

            handleClose();
        },
        [isVisible, handleClose],
    );

    if (modalRefContainer === null || !isVisible) {
        return null;
    }

    const isDialog = variant === 'dialog';
    const isOverlay = !isDialog;

    const closeButton = (
        <CloseButton aria-label={t('frontend.modal.label.close')} data-cy="close-modal" onClick={handleClose} />
    );

    return createPortal(
        <Backdrop isOverlay={isOverlay} hasOverflow={hasOverflow}>
            {isOverlay ? closeButton : null}
            <Root
                data-cy="modal"
                ref={modalRef}
                tabIndex={-1}
                className={className}
                onKeyDown={handleKeyDown}
                role="dialog"
                aria-modal="true"
                isOverlay={isOverlay}
                isFullScreenOnMobile={isFullScreenOnMobile}
            >
                {isDialog && hasCloseButton ? closeButton : null}
                {children}
            </Root>
        </Backdrop>,
        modalRefContainer,
    );
};

export default Modal;
