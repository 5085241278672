import styled from '@emotion/styled';
import { SCREEN_READER_ONLY } from '@lib/styles/partials/helpers';
import { theme } from '@lib/styles/themes/nexus';
import { H4 } from '@nexus/lib-react/dist/core/Typography';

export const ModalWrapper = styled.div`
    display: grid;
    position: fixed;
    z-index: ${theme.zIndices.modal};
    grid-template-rows: auto 1fr auto;
    background-color: ${theme.colors['background-global-primary']};
    inset: 0;
`;

export const ModalHeader = styled.div`
    display: flex;
    gap: ${theme.space[16]};
    align-items: center;
    padding: ${theme.space[16]};
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
`;

export const ModalBody = styled.div`
    overflow: auto;
`;

export const ModalFooter = styled.div`
    padding: ${theme.space[16]};
    border-top: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
`;

export const StyledH4 = styled(H4)`
    margin: 0;
`;

export const StyledButton = styled.button`
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
`;

export const HiddenSpan = styled.span`
    ${SCREEN_READER_ONLY}
`;
