import { H3 as BaseH3 } from '@domains/shared/components/Heading/Heading.theme';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div`
    padding: 24px;
    background-color: ${({ theme }): string => theme.deprecated.domains.search.noSearchResults.backgroundColor};

    @media (min-width: ${BREAKPOINT.md}) {
        display: grid;
        grid-gap: 24px;
        grid-template-areas: 'image content';
        grid-template-columns: 115px 1fr;
    }
`;

export const Image = styled.img`
    grid-area: image;
`;

export const Content = styled.div`
    grid-area: content;
`;

export const H3 = styled(BaseH3)`
    font-size: ${SIZE.h6};
    font-weight: ${WEIGHT.bold};

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.h5};
    }
`;

export const Information = styled.p`
    font-size: ${SIZE.p3};
    line-height: ${LINE_HEIGHT.x1};

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p2};
    }
`;
