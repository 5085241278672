import dayjs from 'dayjs';

const MAX_DAYS_SINCE_AD_CREATION = 7;
const NEW_AD_MAX_DIFF_IN_MS = 7 * 86400000;

export const checkIsNewAdvert = (dateCreatedFirst: string | null): boolean => {
    if (!dateCreatedFirst) {
        return false;
    }

    const timeDiff = Date.now() - new Date(dateCreatedFirst).getTime();

    return timeDiff <= NEW_AD_MAX_DIFF_IN_MS;
};

export const getDaysSinceAdCreation = (dateCreatedFirst: string | null): number => {
    if (!dateCreatedFirst) {
        return 0;
    }

    return dayjs().diff(dayjs(dateCreatedFirst).startOf('day'), 'day');
};

export const checkTimeSinceAdCreationWithinLimit = (daysSinceAdCreation: number): boolean => {
    return daysSinceAdCreation <= MAX_DAYS_SINCE_AD_CREATION;
};
