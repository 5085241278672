import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { JSX } from 'react';

const HEADER_HEIGHT = '60px';

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: calc(100vh - ${HEADER_HEIGHT});
    height: calc(100dvh - ${HEADER_HEIGHT});
    user-select: none;

    @media ${theme.mediaQueries.lgMin} {
        height: 100vh;
    }

    & .price-tag {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        pointer-events: auto;
    }
`;

export const GlobalMapStyles = (): JSX.Element => (
    /* Disable scroll on mobile */
    <Global
        styles={css`
            html body {
                overflow: hidden;
                overscroll-behavior: none;

                @media ${theme.mediaQueries.lgMin} {
                    overflow: auto;
                    overscroll-behavior: auto;
                }
            }
        `}
    />
);
