import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { getEstateTranslationKeyPrefix } from '@domains/shared/helpers/getEstateTranslationKeyPrefix';
import { getTransactionTranslationKeyPrefix } from '@domains/shared/helpers/getTransactionTranslationKeyPrefix';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationLink } from '@type/location/locationLink';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import type { SeoEstate } from '@type/search/filters/seoEstate';
import type { Transaction } from '@type/search/filters/transaction';
import { useMemo } from 'react';

interface AvailableRoomNumber {
    stringValue: RoomsNumber;
    numberValue: number;
}

interface Props {
    location: string | null;
    estate: SeoEstate;
    transaction: Transaction;
    roomsNumber?: RoomsNumber[];
    areMultipleLocationsSelected: boolean | null;
    locationLabel?: string;
}

const AVAILABLE_ROOM_NUMBERS: AvailableRoomNumber[] = [
    {
        stringValue: 'TWO',
        numberValue: 2,
    },
    {
        stringValue: 'THREE',
        numberValue: 3,
    },
    {
        stringValue: 'FOUR',
        numberValue: 4,
    },
    {
        stringValue: 'FIVE',
        numberValue: 5,
    },
];

export const useGetRelatedSearchesWithNumberOfRooms = ({
    location,
    estate,
    transaction,
    roomsNumber,
    areMultipleLocationsSelected,
    locationLabel,
}: Props): LocationLink[] => {
    const [t] = useTranslations();
    const isValidEstate = SEARCH_CONFIG.seo?.internalSeoLinks?.estatesWithRelatedNumberOfRoomsSearches.includes(estate);

    const propertiesWithRelatedNumberOfRooms = useMemo<LocationLink[]>(
        () =>
            (location &&
                !areMultipleLocationsSelected &&
                isValidEstate &&
                AVAILABLE_ROOM_NUMBERS.filter(
                    (availableRoomNumber) => !roomsNumber?.includes(availableRoomNumber.stringValue),
                ).map((availableRoomNumber) => {
                    const estateLabelKey = getEstateTranslationKeyPrefix(estate);
                    const transactionLabelKey = getTransactionTranslationKeyPrefix(transaction);

                    const name = locationLabel
                        ? t(
                              'frontend.search.location-seo-links.related-number-of-rooms-search.link-name-with-location',
                              {
                                  estateLabel: t(estateLabelKey),
                                  transactionLabel: t(transactionLabelKey),
                                  roomsNumberLabel: String(availableRoomNumber.numberValue),
                                  location: locationLabel,
                              },
                          )
                        : t('frontend.search.location-seo-links.related-number-of-rooms-search.link-name', {
                              estateLabel: t(estateLabelKey),
                              transactionLabel: t(transactionLabelKey),
                              roomsNumberLabel: String(availableRoomNumber.numberValue),
                          });

                    return {
                        name,
                        estate,
                        transaction,
                        roomsNumber: [availableRoomNumber.stringValue],
                        location,
                    };
                })) ||
            [],
        [areMultipleLocationsSelected, estate, isValidEstate, location, locationLabel, t, transaction, roomsNumber],
    );

    return propertiesWithRelatedNumberOfRooms;
};
