import type { ValueVariants } from '@domains/search/types/valueVariants';

/**
 * Check if query param and query param values are existing in interface and filter out unforeseen values
 * @param parameters
 * @param variants
 * @private
 */
const filterUnforeseenParameters = (
    parameters: string[] | string | undefined,
    variants: string[],
): string[] | string | null => {
    if (!parameters) {
        return null;
    }

    if (Array.isArray(parameters)) {
        return parameters.filter((param) => variants.includes(param));
    } else if (variants.includes(parameters)) {
        return parameters;
    } else {
        return null;
    }
};

/**
 * Check if there is parameter, then make parameter an array from string (if parameter is "[something,foo,bar]" string),
 * filter out unwanted values and return output.
 * @param parameterValue
 * @param variants
 * @param options
 * @private
 */
interface ParseParameterOptions {
    singleValueVariants?: ValueVariants;
    multipleValueVariants?: ValueVariants;
}

const checkIsArrayParameter = (value: string): boolean => value.startsWith('[') && value.endsWith(']');

const parseArrayParameter = (value: string): string[] => value.slice(1, -1).split(',');

const returnDefaultValue = (options?: ParseParameterOptions): string[] | null => {
    if (options?.multipleValueVariants) {
        return [];
    }

    return null;
};

export const parseParameter = (
    parameterValue: string | string[] | undefined,
    options?: ParseParameterOptions,
): string[] | string | null => {
    const variants = options?.singleValueVariants || options?.multipleValueVariants;

    if (!parameterValue) {
        // Handle lack of parameter
        return returnDefaultValue(options);
    }

    /**
     * For some time we've been serving corrupted one of locations parameter (everything was working fine though).
     * In new notation we have to cut out this parameter.
     * eg. /pl/oferty/sprzedaz/mieszkanie/wiele-lokalizacji?locations=%5B%5Bobject%20Object%5D%2C%5Bobject%20Object%5D%5D&by=DEFAULT&direction=DESC&locations%5B0%5D%5BregionId%5D=16&locations%5B0%5D%5BcityId%5D=213&locations%5B0%5D%5BsubregionId%5D=371&locations%5B1%5D%5BregionId%5D=16&locations%5B1%5D%5BcityId%5D=212&locations%5B1%5D%5BsubregionId%5D=464
     */
    if (parameterValue.includes('object')) {
        return null;
    }

    // Handle array parameters
    if (Array.isArray(parameterValue)) {
        if (parameterValue.every((item) => typeof item === 'string' && !item.startsWith('['))) {
            return parameterValue;
        }

        return returnDefaultValue(options);
    }

    /**
     * Handle array format, eg. [first_element,second_element, ...]
     * URLparameters with multiple values comes as a stringed array with multiple values. Example: roomsNumber="[1, 2, 3]"
     * URLparameters with a single value come as a string. Example: market="primary"?
     * This method checks if parameters is an array or just a string and returns a proper value.
     */
    const params = checkIsArrayParameter(parameterValue) ? parseArrayParameter(parameterValue) : parameterValue;

    // When we have empty array it doesn't filter
    if (variants && variants.length > 0) {
        return filterUnforeseenParameters(params, variants as unknown as string[]);
    }

    // Return simple, plain string param
    return params;
};
