import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Floors } from '@type/search/filters/floors';

export const FLOORS_DEFINITION_TRANSLATIONS: Record<Floors, Parameters<Translator>> = {
    /* eslint-disable @typescript-eslint/naming-convention -- Floors entries are uppercased */
    CELLAR: ['frontend.search.advert-specs-list.floor-value-cellar', undefined],
    GROUND: ['frontend.search.advert-specs-list.floor-value-ground', undefined],
    FIRST: ['frontend.search.advert-specs-list.floor-value-number', { number: '1' }],
    SECOND: ['frontend.search.advert-specs-list.floor-value-number', { number: '2' }],
    THIRD: ['frontend.search.advert-specs-list.floor-value-number', { number: '3' }],
    FOURTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '4' }],
    FIFTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '5' }],
    SIXTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '6' }],
    SEVENTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '7' }],
    EIGHTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '8' }],
    NINTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '9' }],
    TENTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '10' }],
    ABOVE_TENTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '10+' }],
    GARRET: ['frontend.search.advert-specs-list.floor-value-garret', undefined],

    // Storia specific
    ELEVENTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '11' }],
    TWELFTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '12' }],
    ABOVE_THIRTEENTH: ['frontend.search.advert-specs-list.floor-value-number', { number: '13+' }],
    /* eslint-enable @typescript-eslint/naming-convention */
};
