import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import ChevronLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronLeft';
import type { JSX } from 'react';

import {
    HiddenSpan,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalWrapper,
    StyledButton,
    StyledH4,
} from './MobileModal.theme';

interface Props {
    body: JSX.Element;
    title: string;
    footer?: JSX.Element;
    dataTestId?: string;
    onCloseIconClick: () => void;
}

export const MobileModal = ({ body, title, footer, dataTestId, onCloseIconClick }: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <ModalWrapper data-testid={dataTestId}>
            <ModalHeader>
                <StyledButton onClick={onCloseIconClick}>
                    <ChevronLeft />
                    <HiddenSpan>{t('frontend.global.label.close')}</HiddenSpan>
                </StyledButton>
                <StyledH4 variant="regular">{title}</StyledH4>
            </ModalHeader>
            <ModalBody>{body}</ModalBody>
            {footer ? <ModalFooter>{footer}</ModalFooter> : null}
        </ModalWrapper>
    );
};
