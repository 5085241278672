import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { TagProps as NexusTagProps } from '@nexus/lib-react/dist/core/Tag';
import { Tag as NexusTag } from '@nexus/lib-react/dist/core/Tag';
import type { InvestmentState } from '@type/search/filters/investmentState';
import { INVESTMENT_STATE_VARIANTS } from '@type/search/filters/investmentState';
import type { JSX } from 'react';

interface TagProps extends Omit<NexusTagProps, 'content'> {
    isExclusiveOffer?: boolean;
    dateCreated?: number;
    investmentState?: InvestmentState;
    isPromoted?: boolean;
}

export const Tag = (props: TagProps): JSX.Element | null => {
    const [t] = useTranslations();
    const content = getContent(props, t);

    if (!content) {
        return null;
    }

    return <NexusTag {...content} />;
};

const INVESTMENT_STATE_TRANSLATIONS: Record<InvestmentState, string> = {
    [INVESTMENT_STATE_VARIANTS[0]]: 'frontend.search.item.investment-state-not-started',
    [INVESTMENT_STATE_VARIANTS[1]]: 'frontend.search.item.investment-state-in-building',
    [INVESTMENT_STATE_VARIANTS[2]]: 'frontend.search.item.investment-state-ready',
};

const getContent = (props: TagProps, t: Translator): NexusTagProps | null => {
    if (props.investmentState) {
        const stateKey = props.investmentState.toUpperCase() as InvestmentState;
        const translationKey = INVESTMENT_STATE_TRANSLATIONS[stateKey];

        return {
            variant: 'primary',
            content: t(translationKey),
        };
    }

    if (typeof props.dateCreated === 'number') {
        return {
            variant: 'secondary',
            content: getAdvertCreationBadge(props.dateCreated, t),
        };
    }

    if (props.isExclusiveOffer) {
        return {
            variant: 'highlight',
            content: t('frontend.search.item.otodom-only'),
        };
    }

    if (props.isPromoted) {
        return {
            variant: 'highlight',
            content: t('frontend.search.item.promoted'),
        };
    }

    return null;
};

const getAdvertCreationBadge = (daysSinceCreation: number, t: Translator): string => {
    let labelTranslationKey: string;

    switch (daysSinceCreation) {
        case 0: {
            labelTranslationKey = 'frontend.search.listing-item.added-today';
            break;
        }
        case 1: {
            labelTranslationKey = 'frontend.search.listing-item.added-yesterday';
            break;
        }
        default: {
            labelTranslationKey = 'frontend.search.listing-item.added-days-ago';
            break;
        }
    }

    return t(labelTranslationKey, { days: daysSinceCreation.toString() });
};
