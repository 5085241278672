import { LOCALE } from '@lib/i18n/types/locale';
import type { JSX, ReactNode } from 'react';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { MapViewProps } from '../types/mapViewProps';

type MapViewOptions = Pick<MapViewProps, 'lang' | 'filterLocations' | 'filterAttributes'>;

export const SHAPE_SOURCE = { pin: 'PIN', drawingArea: 'DRAWING_AREA' } as const;

type ShapeSource = ObjectValues<typeof SHAPE_SOURCE>;

export const MINI_LISTING_VISIBILITY = { hidden: 'HIDDEN', collapsed: 'COLLAPSED', expanded: 'EXPANDED' } as const;

export type MiniListingVisibility = ObjectValues<typeof MINI_LISTING_VISIBILITY>;

export interface MapViewMethods {
    miniListingVisibility: MiniListingVisibility;
    selectedIds: number[];
    visitedIds: number[];
    selectedShape: string | null;
    visitedShape: string[];
    selectedShapeSource: ShapeSource | null;
    totalAdsInMiniListing: number;
    totalAdsInLastDrawArea: number;
    showMiniListingForIds: (value: number | number[]) => void;
    showMiniListingForShape: (value: string, source: ShapeSource) => void;
    /**
     * @summary by default if clearMemoizedDrawArea true is not provided it will collapse mini listing if memoizedDrawArea detected
     * @important it will clear all selected values
     */
    hideMiniListing: (clearMemoizedDrawArea?: boolean) => void;
    setTotalAdsInMiniListing: (value: number) => void;
    collapseMiniListing: () => void;
    /**
     * @summary mini listing will be expanded only if memoizedDrawArea exist, in other case use showMiniListingForIds/showMiniListingForShape to trigger expanded view
     */
    expandMiniListing: () => void;
    options: MapViewOptions;
}

const INITIAL_SELECTED = {
    ids: [],
    shape: null,
    shapeSource: null,
};
export const MapViewContext = createContext<MapViewMethods>({
    miniListingVisibility: MINI_LISTING_VISIBILITY.hidden,
    selectedIds: [],
    visitedIds: [],
    visitedShape: [],
    selectedShape: null,
    selectedShapeSource: null,
    totalAdsInMiniListing: 0,
    totalAdsInLastDrawArea: 0,
    showMiniListingForIds: () => undefined,
    hideMiniListing: () => undefined,
    showMiniListingForShape: () => undefined,
    setTotalAdsInMiniListing: () => undefined,
    collapseMiniListing: () => undefined,
    expandMiniListing: () => undefined,
    options: {
        lang: LOCALE.pl,
        filterLocations: {},
        filterAttributes: {},
    },
});

export const MapViewProvider = ({
    children,
    lang,
    filterLocations,
    filterAttributes,
}: MapViewOptions & { children: ReactNode }): JSX.Element => {
    const [miniListingVisibility, setMiniListingVisibility] = useState<MiniListingVisibility>(
        MINI_LISTING_VISIBILITY.hidden,
    );
    const [selected, setSelected] = useState<{
        ids: number[];
        shape: string | null;
        shapeSource: ShapeSource | null;
    }>(INITIAL_SELECTED);

    const lastDrawingShapeRef = useRef<string | null>(null);
    const [totalAdsInLastDrawArea, setLastDrawAreaAdsCount] = useState(0);
    const [totalAdsInMiniListing, setTotalAdsInMiniListing] = useState(0);

    const [visitedIds, setVisitedIds] = useState<number[]>([]);
    const [visitedShape, setVisitedShape] = useState<string[]>([]);

    const showMiniListingForIds = useCallback(
        (value: number | number[]): void => {
            const isArray = Array.isArray(value);
            const ids = isArray ? value : [value];
            setSelected({
                ids,
                shape: null,
                shapeSource: null,
            });
            setVisitedIds([...visitedIds, ...ids]);
            setMiniListingVisibility(MINI_LISTING_VISIBILITY.expanded);
        },
        [visitedIds],
    );

    const showMiniListingForShape = useCallback(
        (shape: string, shapeSource: ShapeSource) => {
            setSelected({
                ids: [],
                shape,
                shapeSource,
            });
            setVisitedShape([...visitedShape, shape]);
            setMiniListingVisibility(MINI_LISTING_VISIBILITY.expanded);
            if (shapeSource === SHAPE_SOURCE.drawingArea) {
                lastDrawingShapeRef.current = shape;
            }
        },
        [visitedShape],
    );

    const collapseMiniListing = useCallback(() => {
        setMiniListingVisibility(MINI_LISTING_VISIBILITY.collapsed);
    }, []);

    const expandMiniListing = useCallback(() => {
        setMiniListingVisibility(MINI_LISTING_VISIBILITY.expanded);
        if (lastDrawingShapeRef.current) {
            setSelected({
                ids: [],
                shape: lastDrawingShapeRef.current,
                shapeSource: SHAPE_SOURCE.drawingArea,
            });
        }
    }, []);

    const hideMiniListing = useCallback((clearMemoizedDrawArea = false) => {
        if (clearMemoizedDrawArea) {
            lastDrawingShapeRef.current = null;
            setLastDrawAreaAdsCount(0);
        }

        const nextMiniListingVisibility = lastDrawingShapeRef.current
            ? MINI_LISTING_VISIBILITY.collapsed
            : MINI_LISTING_VISIBILITY.hidden;
        setMiniListingVisibility(nextMiniListingVisibility);

        setSelected(INITIAL_SELECTED);

        setTotalAdsInMiniListing(0);
    }, []);

    const setTotalAdsInMiniListingHandler = useCallback(
        (value: number) => {
            setTotalAdsInMiniListing(value);
            if (selected.shapeSource === SHAPE_SOURCE.drawingArea) {
                setLastDrawAreaAdsCount(value);
            }
        },
        [selected.shapeSource],
    );

    const mapOptions = useMemo(
        () => ({
            miniListingVisibility,
            hideMiniListing,
            showMiniListingForIds,
            showMiniListingForShape,
            setTotalAdsInMiniListing: setTotalAdsInMiniListingHandler,
            collapseMiniListing,
            expandMiniListing,
            totalAdsInMiniListing,
            totalAdsInLastDrawArea,
            selectedShape: selected.shape,
            selectedShapeSource: selected.shapeSource,
            selectedIds: selected.ids,
            visitedIds,
            visitedShape,
            options: { lang, filterAttributes: { ...filterAttributes, distanceRadius: 0 }, filterLocations },
        }),
        [
            miniListingVisibility,
            hideMiniListing,
            showMiniListingForIds,
            showMiniListingForShape,
            setTotalAdsInMiniListingHandler,
            collapseMiniListing,
            expandMiniListing,
            totalAdsInMiniListing,
            totalAdsInLastDrawArea,
            selected.shape,
            selected.shapeSource,
            selected.ids,
            visitedIds,
            visitedShape,
            lang,
            filterAttributes,
            filterLocations,
        ],
    );

    useEffect(() => {
        setSelected({
            ids: [],
            shape: lastDrawingShapeRef.current,
            shapeSource: lastDrawingShapeRef.current ? SHAPE_SOURCE.drawingArea : null,
        });
        setLastDrawAreaAdsCount(0);
        setTotalAdsInMiniListing(0);
        setMiniListingVisibility(
            lastDrawingShapeRef.current ? MINI_LISTING_VISIBILITY.expanded : MINI_LISTING_VISIBILITY.hidden,
        );
    }, [filterLocations, filterAttributes]);

    return <MapViewContext.Provider value={mapOptions}>{children}</MapViewContext.Provider>;
};
