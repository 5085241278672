import { getLocationAddress } from '@domains/shared/helpers/getLocationAddress';
import type { Street } from '@type/location/address';
import type { JSX } from 'react';

import { StyledParagraph } from './Address.theme';

export interface Location {
    fullName: string;
    [key: string]: unknown;
}

interface Props {
    street: Street | null;
    radius: number;
    locations: Location[];
}

export const Address = ({ street, radius, locations, ...props }: Props): JSX.Element | null => {
    const address = getLocationAddress({ street, radius, locations });

    if (!address) {
        return null;
    }

    return (
        <StyledParagraph data-testid="advert-card-address" {...props}>
            {address}
        </StyledParagraph>
    );
};
