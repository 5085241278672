import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

interface Props {
    isDisplayedOnImage?: boolean;
}

export const PointerEventsButton = styled(Button)`
    position: relative;
    pointer-events: auto;
`;

const DEFAULT_STYLES = css`
    position: relative;
`;

const DISPLAYED_ON_IMAGE_STYLES = {
    mobile: css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    `,
    tablet: css`
        position: absolute;
        top: 256px;
    `,
};

export const GoToMapViewButton = styled(Button)<Props>`
    background-color: ${theme.colors.$01_white};

    ${({ isDisplayedOnImage }): SerializedStyles =>
        isDisplayedOnImage ? DISPLAYED_ON_IMAGE_STYLES.mobile : DEFAULT_STYLES}

    @media (min-width: ${BREAKPOINT.md}) {
        ${({ isDisplayedOnImage }): SerializedStyles =>
            isDisplayedOnImage ? DISPLAYED_ON_IMAGE_STYLES.tablet : DEFAULT_STYLES}
    }
`;
