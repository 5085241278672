import { Collapsible } from '@domains/shared/components/Collapsible/Collapsible';
import { NexusSummaryWithIcon } from '@domains/shared/components/Collapsible/NexusSummaryWithIcon';
import { StyledWrapper } from '@domains/shared/components/Collapsible/NexusSummaryWithIcon.theme';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const CollapseHeader = styled.div`
    color: ${theme.colors['text-global-secondary']};
    text-align: left;
`;

export const CollapsibleSection = styled(Collapsible)`
    color: ${theme.colors['text-global-secondary']};

    ${StyledWrapper} {
        justify-content: flex-start;
    }
`;
export const DescriptionTitle = styled(NexusSummaryWithIcon)`
    display: inline-block;
    position: relative;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes.p3};
    font-weight: ${theme.fontWeights[400]};
    letter-spacing: 0;
    line-height: ${theme.lineHeights[20]};
    text-align: left;
    user-select: none;
`;

export const DescriptionText = styled.div`
    display: -webkit-box;
    margin-top: ${theme.space[4]};
    overflow: hidden;
    color: ${theme.colors['text-global-secondary']};
    overflow-wrap: break-word;
    font-size: ${theme.fontSizes.p3};
    font-weight: ${theme.fontWeights[400]};
    letter-spacing: 0;
    line-height: ${theme.lineHeights[20]};
    word-break: break-all;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;
