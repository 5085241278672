import styled from '@emotion/styled';
import ArrowDown from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowDown';

export const NexusStyledIcon = styled(ArrowDown)<{ state: string }>`
    transform: ${({ state }): string => (state === 'open' || state === 'opened' ? 'rotate(-180deg)' : 'rotate(0)')};
    transition: transform 200ms linear;
    color: inherit;
`;

export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledChildrenWrapper = styled.div`
    margin-right: 8px;
`;
