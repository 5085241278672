import { MapSkeleton } from '@domains/search/components/MapView/MapSkeleton';
import { MapViewProvider } from '@domains/search/contexts/MapViewContext';
import type { MapViewProps } from '@domains/search/types/mapViewProps';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { memo } from 'react';

import { GlobalMapStyles, Wrapper } from './MapView.theme';

const LazyMapContainer = dynamic(() => import('@domains/search/components/MapView/Container'), {
    ssr: false,
    loading: () => <MapSkeleton />,
});

type Props = Omit<MapViewProps, 'selectedFiltersNumber'>;

const BaseMapView = (props: Props): JSX.Element => (
    <Wrapper data-cy="search.map" data-testid="map">
        <LazyMapContainer
            initialBounds={props.initialBounds}
            initialArea={props.initialArea}
            handleOpenSearchFormModal={props.handleOpenSearchFormModal}
        />
    </Wrapper>
);

const MemoizedMapView = memo(BaseMapView);

export const MapView = (props: Props): JSX.Element => (
    <MapViewProvider {...props}>
        <MemoizedMapView {...props} />
        <GlobalMapStyles />
    </MapViewProvider>
);
