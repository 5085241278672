import { SORTING_BY } from './by';
import { SORTING_DIRECTION } from './direction';

export const SORTING_BUTTON_OPTION = {
    default: `${SORTING_BY.default}-${SORTING_DIRECTION.desc}`,
    latest: `${SORTING_BY.latest}-${SORTING_DIRECTION.desc}`,
    price: `${SORTING_BY.price}-${SORTING_DIRECTION.asc}`,
    bestMatch: `${SORTING_BY.bestMatch}-${SORTING_DIRECTION.desc}`,
} as const;

export type SortingButtonOption = ObjectValues<typeof SORTING_BUTTON_OPTION>;
