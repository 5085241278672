import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { getEstateTranslationKeyPrefix } from '@domains/shared/helpers/getEstateTranslationKeyPrefix';
import { getTransactionTranslationKeyPrefix } from '@domains/shared/helpers/getTransactionTranslationKeyPrefix';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationLink } from '@type/location/locationLink';
import type { SeoEstate } from '@type/search/filters/seoEstate';
import { SEO_ESTATE } from '@type/search/filters/seoEstate';
import type { Transaction } from '@type/search/filters/transaction';
import { TRANSACTION } from '@type/search/filters/transaction';
import type { UseTypes } from '@type/search/filters/useTypes';
import { useMemo } from 'react';

interface Props {
    location: string | null;
    estate: SeoEstate;
    transaction: Transaction;
    areMultipleLocationsSelected: boolean | null;
    locationLabel?: string;
    useTypes?: UseTypes[];
}

export const useGetPropertiesForRent = ({
    location,
    estate,
    transaction,
    areMultipleLocationsSelected,
    locationLabel,
    useTypes,
}: Props): LocationLink[] => {
    const [t] = useTranslations();

    const propertiesForRent = useMemo<LocationLink[]>(
        () =>
            (location &&
                !areMultipleLocationsSelected &&
                SEARCH_CONFIG.seo?.internalSeoLinks?.propertiesForRent
                    .filter(
                        (estateType) =>
                            !(
                                transaction === TRANSACTION.rent &&
                                (useTypes?.toString() === SEO_ESTATE.office
                                    ? estateType === SEO_ESTATE.office
                                    : estateType === estate)
                            ),
                    )
                    .map((estateType) => {
                        const estateLabelKey = getEstateTranslationKeyPrefix(estateType);
                        const transactionLabelKey = getTransactionTranslationKeyPrefix(TRANSACTION.rent);

                        const name = locationLabel
                            ? t('frontend.search.location-seo-links.link-name-with-location', {
                                  estateLabel: t(estateLabelKey),
                                  transactionLabel: t(transactionLabelKey),
                                  location: locationLabel,
                              })
                            : t('frontend.search.location-seo-links.link-name', {
                                  estateLabel: t(estateLabelKey),
                                  transactionLabel: t(transactionLabelKey),
                              });

                        return {
                            name,
                            estate: estateType,
                            transaction: TRANSACTION.rent,
                            location,
                        };
                    })) ||
            [],
        [areMultipleLocationsSelected, estate, location, locationLabel, t, transaction, useTypes],
    );

    return propertiesForRent;
};
