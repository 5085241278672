import type { AddToFavorites } from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { createAddToFavoritesProps } from '@domains/shared/helpers/createAddToFavoritesProps';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import type { ComponentProps } from 'react';
import { useState } from 'react';

export const useAddToFavorites = ({
    id,
    isPromoted,
    isNearbySection,
    setSimilarAdsPromptId,
}: {
    id: number;
    isPromoted: boolean;
    isNearbySection: boolean;
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
}): { addToFavoritesProps: ComponentProps<typeof AddToFavorites>; isRedirectToLoginModalOpen: boolean } => {
    const [isRedirectToLoginModalOpen, setIsRedirectToLoginModalOpen] = useState<boolean>(false);

    const addToFavoritesProps = createAddToFavoritesProps({
        id,
        isPromoted,
        isNearbySection,
        isRedirectToLoginModalOpen,
        setIsRedirectToLoginModalOpen,
        setSimilarAdsPromptId,
    });

    return { addToFavoritesProps, isRedirectToLoginModalOpen };
};
