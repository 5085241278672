import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const StyledTitle = styled.p`
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    font-size: ${theme.fontSizes.p2};
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
