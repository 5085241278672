import type { TrackEvent } from '@lib/tracking/types';
import { useTracking } from '@lib/tracking/useTracking';
import { useEffect, useRef } from 'react';

export const useDeferredAbortableTracking = (delay: number): { trackEvent: TrackEvent; abortTracking: () => void } => {
    const { trackEvent: originalTrackEvent } = useTracking();
    const timeoutIdRef = useRef<number>();

    const trackEvent: TrackEvent = (...options): void => {
        const id = window.setTimeout(() => originalTrackEvent(...options), delay);
        timeoutIdRef.current = id;
    };

    const abortTracking = (): void => {
        window.clearTimeout(timeoutIdRef.current);
    };

    useEffect(() => {
        return (): void => {
            window.clearTimeout(timeoutIdRef.current);
        };
    }, []);

    return {
        trackEvent,
        abortTracking,
    };
};
