import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { getDescriptionParameter } from '@domains/shared/helpers/getDescriptionParameter';
import { getEstateType } from '@domains/shared/helpers/getEstateType';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { LocationLink } from '@type/location/locationLink';
import type { Market } from '@type/search/filters/market';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import { SEO_ESTATE } from '@type/search/filters/seoEstate';
import { COMMERCIAL_PROPERTY_USE_TYPE } from '@type/search/filters/useTypes';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import type { JSX } from 'react';

import { Container, Link, OneColumnListContainer, SectionHeading } from './CustomLocationSeoLinks.theme';

interface Props {
    heading?: string;
    items: LocationLink[];
    market?: Market;
}

export const CustomLocationSeoLinks = ({ heading, items, market }: Props): JSX.Element => {
    const { trackEvent } = useTracking();

    return (
        <Container>
            {heading ? <SectionHeading>{heading}</SectionHeading> : null}

            <OneColumnListContainer>
                {items.map(({ name, estate, transaction, location, roomsNumber }) => {
                    return (
                        <CustomSeoLinkItem
                            key={`${estate}-${transaction}-${name}`}
                            name={name}
                            estate={estate}
                            transaction={transaction}
                            location={location}
                            market={market}
                            roomsNumber={roomsNumber}
                            onClick={(): void => trackEvent('seo_link_click')}
                        />
                    );
                })}
            </OneColumnListContainer>
        </Container>
    );
};

const CustomSeoLinkItem = ({
    name,
    estate,
    transaction,
    location,
    market,
    roomsNumber,
    onClick,
}: {
    name: LocationLink['name'];
    estate: LocationLink['estate'];
    transaction: LocationLink['transaction'];
    location: LocationLink['location'];
    market?: Market;
    roomsNumber?: RoomsNumber[];
    onClick: () => void;
}): JSX.Element => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();

    return (
        <li>
            <LocalLink
                passHref
                href={buildSearchUrl({
                    lang,
                    transaction,
                    estate: getEstateType(estate),
                    location,
                    market,
                    roomsNumber,
                    useTypes: estate === SEO_ESTATE.office ? [COMMERCIAL_PROPERTY_USE_TYPE.office] : undefined,
                    queryParameters: { description: getDescriptionParameter(estate, t) },
                })}
            >
                <Link onClick={onClick}>{name}</Link>
            </LocalLink>
        </li>
    );
};
