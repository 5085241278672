import type { LocationLink } from '@type/location/locationLink';
import { ESTATE } from '@type/search/filters/estate';
import type { Market } from '@type/search/filters/market';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import type { SeoEstate } from '@type/search/filters/seoEstate';
import type { Transaction } from '@type/search/filters/transaction';
import { TRANSACTION } from '@type/search/filters/transaction';
import type { UseTypes } from '@type/search/filters/useTypes';
import type { Location } from '@type/search/searchingFilters';

import { useGetPropertiesForRent } from './useGetPropertiesForRent';
import { useGetPropertiesForSell } from './useGetPropertiesForSell';
import { useGetPropertiesFromPrimaryMarket } from './useGetPropertiesFromPrimaryMarket';
import { useGetRelatedSearchesWithNumberOfRooms } from './useGetRelatedSearchesWithNumberOfRooms';

interface Props {
    location: string | null;
    locationData?: Location[] | string[] | string | null;
    estate: SeoEstate;
    transaction: Transaction;
    market?: Market;
    roomsNumber?: RoomsNumber[];
    useTypes?: UseTypes[];
}

export const useGetLocationLinks = ({
    location,
    locationData,
    estate,
    transaction,
    market,
    roomsNumber,
    useTypes,
}: Props): {
    propertiesForSell: LocationLink[];
    propertiesForRent: LocationLink[];
    propertiesFromPrimaryMarket: LocationLink[];
    relatedSearchesWithNumberOfRooms: LocationLink[];
} => {
    const areMultipleLocationsSelected = Array.isArray(locationData) && locationData?.length > 1;
    const shouldDisplayPrimaryMarket = transaction !== TRANSACTION.rent && estate !== ESTATE.terrain;

    let locationLabel: string | undefined;

    if (Array.isArray(locationData) && locationData?.length === 1) {
        locationLabel = typeof locationData[0] === 'string' ? locationData[0] : locationData[0]?.name?.split(',')[0];
    } else if (typeof locationData === 'string') {
        locationLabel = locationData;
    }

    const propertiesForSell = useGetPropertiesForSell({
        location,
        estate,
        transaction,
        market,
        areMultipleLocationsSelected,
        locationLabel,
        useTypes,
    });

    const propertiesForRent = useGetPropertiesForRent({
        location,
        estate,
        transaction,
        areMultipleLocationsSelected,
        locationLabel,
        useTypes,
    });

    const propertiesFromPrimaryMarket = useGetPropertiesFromPrimaryMarket({
        location,
        estate,
        transaction,
        areMultipleLocationsSelected,
        shouldDisplayPrimaryMarket,
        locationLabel,
    });

    const relatedSearchesWithNumberOfRooms = useGetRelatedSearchesWithNumberOfRooms({
        location,
        estate,
        transaction,
        roomsNumber,
        areMultipleLocationsSelected,
        locationLabel,
    });

    return {
        propertiesForRent,
        propertiesForSell,
        propertiesFromPrimaryMarket,
        relatedSearchesWithNumberOfRooms,
    };
};
