export const EXPERIMENT = {
    /** Show "compare ads" modal with simple survey instead of real feature */
    eure25548: 'EURE-25548',
    /** Simplified geometry for static map */
    eure26492: 'EURE-26492',
    /** AI sorting with CTR */
    eure27497: 'EURE-27497',
    /** Make reply CTA on ad-tile gallery on map smaller */
    eure27170: 'EURE-27170',
    /** Sticky bar bigger map button with background */
    eure27419: 'EURE-27419',
    /** Redesigned map view with listing – first MVP */
    eure20006: 'EURE-20006',
    /** Pet friendly AI filter */
    eure28840: 'EURE-28840',
    /** Redesigned map view with listing and drawing on map – second MVP */
    eure29784: 'EURE-29784',
} as const;
