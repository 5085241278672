import type { FieldMetadata } from '@type/search/fields';
import type { Estate } from '@type/search/filters/estate';
import type { Transaction } from '@type/search/filters/transaction';

/**
 * Check if the field name form the field metadata should be available for the specific estate type.
 * Note: this is naive check - check .only property if it does not exist - return true, otherwise, limit to one field only.
 */
export const checkIsFieldMetadataAvailableForTransactionEstateType = (
    metadata: FieldMetadata,
    estate: Estate,
    transaction: Transaction,
): boolean => {
    return !metadata.only || metadata.only.includes(`${estate}_${transaction}`);
};
