import { SITE_CONFIG } from '@config/siteConfig';
import { getFormattedPrice } from '@domains/shared/helpers/getFormattedPrice';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Money } from '@type/pricing/money';

export const getPrice = (
    t: Translator,
    price: Money,
    {
        isEstimated,
        isPerMeter,
    }: {
        isEstimated?: boolean;
        isPerMeter?: boolean;
    },
): string => {
    let result = '';

    if (isEstimated) {
        result += `${t('frontend.global.price.from')} `;
    }

    result += getFormattedPrice(price);

    if (isPerMeter) {
        result += `/${SITE_CONFIG.defaultUnits.area}`;
    }

    return result;
};
