import type { Position } from '@type/location/position';
import type { ListingItemPrice } from '@type/pricing/listingItemPrice';

export interface FoundMapPinsItem {
    items: Omit<Marker, 'type'>[];
    type: MarkerType;
}

export type SearchMapPinsResult = FoundMapPinsItem[];

export const MARKER_TYPE = {
    single: 'SINGLE',
    cluster: 'CLUSTER',
    processedCluster: 'PROCESSED_CLUSTER', //frontend cluster
} as const;

export type MarkerType = ObjectValues<typeof MARKER_TYPE>;

export interface Marker extends Position {
    value: number | number[];
    radiusInMeters?: number;
    type: MarkerType;
    ad?: { totalPrice?: ListingItemPrice };
    shape?: string;
}
