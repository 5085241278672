import { GalleryBullets } from '@domains/shared/components/GalleryBullets/GalleryBullets';
import type { SerializedStyles } from '@emotion/react';
import type { CarouselProps } from '@nexus/lib-react/dist/core/Carousel/Carousel';
import { CarouselLight } from '@nexus/lib-react/dist/core/Carousel/CarouselLight';
import Camera from '@nexus/lib-react/dist/core/Icon/icons/default/Camera';
import PictureIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Picture';
import type { JSX, PropsWithChildren } from 'react';
import { Children } from 'react';

import { GalleryContainer, ImageFallbackSlideWrapper } from './Gallery.theme';

type Props = PropsWithChildren<{
    controlledActive?: number;
    onChange?: (newIndex: number) => void;
    imgStyles?: SerializedStyles;
    hasOutermostArrow?: boolean;
}>;

export const Gallery = ({ children, controlledActive, onChange, imgStyles, hasOutermostArrow }: Props): JSX.Element => {
    const childrenCount = Children.count(children);

    const shouldShowSlideCounter = childrenCount > 1;
    const shouldShowFallback = childrenCount === 0;

    return (
        <GalleryContainer data-testid="image-gallery-wrapper" imgStyles={imgStyles}>
            <CarouselLight
                controlledActive={controlledActive}
                hideOutermostArrow={!hasOutermostArrow}
                showNavigationButtons
                showSlideCounter={shouldShowSlideCounter}
                renderCounterIcon={CounterIcon}
                renderProgressIndicator={ProgressIndicator}
                onChange={onChange}
            >
                {shouldShowFallback ? <ImageFallbackSlide /> : children}
            </CarouselLight>
        </GalleryContainer>
    );
};

const ImageFallbackSlide = (): JSX.Element => {
    return (
        <ImageFallbackSlideWrapper data-testid="no-slides-fallback">
            <PictureIcon size={32} color="currentColor" />
        </ImageFallbackSlideWrapper>
    );
};

const CounterIcon: CarouselProps['renderCounterIcon'] = (props) => {
    return <Camera {...props} />;
};

const ProgressIndicator: CarouselProps['renderProgressIndicator'] = (props) => {
    const { activeItemIndex, numberOfItems } = props;

    return <GalleryBullets currentIndex={activeItemIndex} slidesCount={numberOfItems} />;
};
