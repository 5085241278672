import { ESTATE } from '@type/search/filters/estate';
import { SEO_ESTATE } from '@type/search/filters/seoEstate';

export const getEstateType = (estate: string): string => {
    if (estate === SEO_ESTATE.office) {
        return ESTATE.commercialProperty;
    }

    if (estate === SEO_ESTATE.villas) {
        return ESTATE.house;
    }

    if (estate === SEO_ESTATE.penthouses) {
        return ESTATE.flat;
    }

    return estate;
};
