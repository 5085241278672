import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

const DEFAULT_IMG_STYLES = css`
    img {
        aspect-ratio: 4 / 3;
        object-fit: cover;
    }

    @media ${theme.mediaQueries.lgMin} {
        img {
            aspect-ratio: 16 / 9;
        }
    }
`;

export const GalleryContainer = styled.div<{ imgStyles?: SerializedStyles }>`
    position: relative;
    height: 100%;

    & > div,
    & > div > div:first-of-type,
    & > div > div:first-of-type > div {
        height: 100%;
    }

    ${({ imgStyles }): SerializedStyles => imgStyles ?? DEFAULT_IMG_STYLES}

    ol {
        position: absolute;
        bottom: 0;
        align-items: end;
    }

    & > div > button {
        position: absolute;
        padding: ${theme.space[8]};
        cursor: pointer;

        svg {
            width: ${theme.sizes[32]};
            height: ${theme.sizes[32]};
            padding: ${theme.space[6]};
            border-radius: 100%;
            background-color: ${theme.colors['$07_black']}75;
        }
    }
`;

export const ImageFallbackSlideWrapper = styled.div`
    display: grid;
    height: 100%;
    background-color: ${theme.colors['background-brand-tertiary']};
    color: ${theme.colors['icon-global-disabled']};
    place-items: center;
`;
