import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { SpecialOfferValue } from '@type/ad/specialOffer';
import { SPECIAL_OFFER_VARIANT } from '@type/ad/specialOffer';
import type { JSX } from 'react';

import { AdditionalTitlePart, DiscountPercentage, Header, Icon, Title } from './SpecialOfferHeader.theme';

export interface Props {
    discountType: SpecialOfferValue;
    discountValue?: number | null;
    displayAdditionalTitlePart?: boolean | null;
    isDisplayedInAside?: boolean;
    className?: string;
}

const DISCOUNT_LABELS = {
    [SPECIAL_OFFER_VARIANT.priceDiscount]: 'frontend.shared.special-offer-header.discount-promotional-price',
    [SPECIAL_OFFER_VARIANT.freeParking]: 'frontend.shared.special-offer-header.discount-free-parking',
    [SPECIAL_OFFER_VARIANT.attractivePaymentSchedule]: 'frontend.shared.special-offer-header.discount-payment-schedule',
    [SPECIAL_OFFER_VARIANT.preSale]: 'frontend.shared.special-offer-header.pre-sale',
    [SPECIAL_OFFER_VARIANT.lastMinute]: 'frontend.shared.special-offer-header.last-minute',
} as const;

export const SpecialOfferHeader = ({
    discountType,
    discountValue,
    displayAdditionalTitlePart,
    className,
}: Props): JSX.Element => {
    const [t] = useTranslations();

    const headerText =
        discountType === SPECIAL_OFFER_VARIANT.priceDiscount
            ? replacePlaceholders(t(DISCOUNT_LABELS[discountType], { percent: `${discountValue ?? 0}` }), (text) => (
                  <DiscountPercentage>{text}</DiscountPercentage>
              ))
            : t(DISCOUNT_LABELS[discountType]);

    return (
        <Header data-testid="special-offer-header" className={className}>
            <Icon />
            <div>
                <Title>{headerText}</Title>
                {displayAdditionalTitlePart ? (
                    <AdditionalTitlePart>
                        {t('frontend.shared.special-offer-header.discount-some-units')}
                    </AdditionalTitlePart>
                ) : null}
            </div>
        </Header>
    );
};
