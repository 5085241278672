import { getSortingTrackingData } from '@domains/search/helpers/getSortingTrackingData';
import { useSearchListingSortingProps } from '@domains/search/hooks/useSearchListingSortingProps';
import { NexusDropdown } from '@domains/shared/components/Dropdown/NexusDropdown';
import { MobileModal } from '@domains/shared/components/nexus/MobileModal/MobileModal';
import { checkIsArrayValue } from '@domains/shared/helpers/checkIsArrayValue';
import { logError } from '@domains/shared/helpers/logger';
import { changeSearchQueryParams } from '@domains/shared/helpers/searchQuery';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { ListItem } from '@nexus/lib-react/dist/core/List';
import { SORTING_BY_VALUES } from '@type/sorting/by';
import { SORTING_DIRECTION_VALUES } from '@type/sorting/direction';
import type { SortingOption } from '@type/sorting/option';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import type { SingleValue } from 'react-select';

import { SORTING_CATEGORY_NAME, SORTING_DIRECTION_NAME } from './constants/sorting';
import type { SortingVariant } from './helpers/createDropdownOptionsData';
import { createDropdownOptionsData } from './helpers/createDropdownOptionsData';
import { getSelectedSortingOption } from './helpers/getSelectedSortingOption';
import { NexusDropdownWrapper, StyledListContainer } from './NexusListingSorting.theme';

interface ModalListingSortingProps {
    sortingOption: SortingOption;
    shouldDisplayAsModal: true;
    onCloseModalClick: () => void;
    onSort?: () => void;
    className?: string;
}

interface DropdownListingSortingProps {
    sortingOption: SortingOption;
    shouldDisplayAsModal?: false;
    onCloseModalClick?: undefined;
    onSort?: () => void;
    className?: string;
}

type Props = ModalListingSortingProps | DropdownListingSortingProps;

export const NexusListingSorting = ({
    sortingOption,
    shouldDisplayAsModal,
    onCloseModalClick,
    onSort,
    className,
}: Props): JSX.Element => {
    const { trackEvent } = useTracking();

    const { allSortingOptions, sortingValues } = useSearchListingSortingProps();

    const sortingOptions = createDropdownOptionsData(allSortingOptions, sortingValues);
    const defaultSelectedSortingOption = getSelectedSortingOption(sortingOptions, sortingOption);
    const [selectedSortingOption, setSelectedSortingOption] = useState(defaultSelectedSortingOption);

    const updateSorting = (sortingOption: SortingVariant): void => {
        setSelectedSortingOption(sortingOption);

        changeSearchQueryParams([
            { name: SORTING_CATEGORY_NAME, value: sortingOption.value },
            { name: SORTING_DIRECTION_NAME, value: sortingOption.direction },
        ]);

        onSort?.();

        const trackingData = getSortingTrackingData({
            by: sortingOption.value,
            direction: sortingOption.direction,
        });

        trackEvent('sort_order_change', trackingData);
    };

    if (shouldDisplayAsModal) {
        return (
            <ModalSorting
                sortingOptions={sortingOptions}
                selectedSortingOption={selectedSortingOption}
                updateSorting={updateSorting}
                onCloseModalClick={onCloseModalClick}
            />
        );
    }

    return (
        <DropdownSorting
            sortingOptions={sortingOptions}
            selectedSortingOption={selectedSortingOption}
            updateSorting={updateSorting}
            className={className}
        />
    );
};

const ModalSorting = ({
    sortingOptions,
    selectedSortingOption,
    updateSorting,
    onCloseModalClick,
}: {
    sortingOptions: SortingVariant[];
    selectedSortingOption: SortingVariant;
    updateSorting: (sortingOption: SortingVariant) => void;
    onCloseModalClick: () => void;
}): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleMobileListItemClick = (sortingOption: SortingVariant): void => {
        if (sortingOption.label !== selectedSortingOption.label) {
            updateSorting(sortingOption);
        }
    };

    useEffect(() => {
        const trackingData = getSortingTrackingData({
            by: selectedSortingOption.value,
            direction: selectedSortingOption.direction,
        });

        trackEvent('sort_order_click', trackingData);
    }, [selectedSortingOption, trackEvent]);

    return (
        <MobileModal
            dataTestId="listing-sorting-mobile-modal"
            title={t('frontend.search.mobile-listing-buttons.sort')}
            onCloseIconClick={onCloseModalClick}
            body={
                <StyledListContainer>
                    {sortingOptions.map((sortingOption) => {
                        const isSelected = sortingOption.label === selectedSortingOption.label;

                        return (
                            <ListItem
                                isSelected={isSelected}
                                onClick={(): void => handleMobileListItemClick(sortingOption)}
                                key={sortingOption.label}
                            >
                                {t(sortingOption.label)}
                            </ListItem>
                        );
                    })}
                </StyledListContainer>
            }
        />
    );
};

const DropdownSorting = ({
    sortingOptions,
    selectedSortingOption,
    updateSorting,
    className,
}: {
    sortingOptions: SortingVariant[];
    selectedSortingOption: SortingVariant;
    updateSorting: (sortingOption: SortingVariant) => void;
    className?: string;
}): JSX.Element => {
    const { trackEvent } = useTracking();

    const dropdownOptions = sortingOptions.map(({ label, value, direction }) => {
        return {
            label,
            value: `${value}-${direction}`,
        };
    });

    const selectedDropdowOption = {
        label: selectedSortingOption.label,
        value: `${selectedSortingOption.value}-${selectedSortingOption.direction}`,
    };

    const handleDropdownChange = (option: SingleValue<{ label: string; value: string }>): void => {
        if (!option) {
            return;
        }

        const [by, direction] = option.value.split('-');

        const isValidSorting =
            checkIsArrayValue(SORTING_DIRECTION_VALUES, direction) && checkIsArrayValue(SORTING_BY_VALUES, by);

        if (!isValidSorting) {
            logError('Invalid sorting provided in handleSortButtonChange', { by, direction });

            return;
        }

        updateSorting({ value: by, direction, label: option.label });
    };

    const handleDropdownMenuOpen = (): void => {
        const trackingData = getSortingTrackingData({
            by: selectedSortingOption.value,
            direction: selectedSortingOption.direction,
        });

        trackEvent('sort_order_click', trackingData);
    };

    return (
        <NexusDropdownWrapper className={className} data-testid="sorting-dropdown">
            <NexusDropdown
                name="listingSorting"
                instanceId="listingSorting"
                variant="primary"
                isSearchable={false}
                options={dropdownOptions}
                onChange={handleDropdownChange}
                onMenuOpen={handleDropdownMenuOpen}
                value={selectedDropdowOption}
            />
        </NexusDropdownWrapper>
    );
};
