import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { TextContainer } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    padding: ${theme.space[32]} 0;
    border-top: 1px solid;
    border-color: ${theme.colors.$03_gray};
`;

export const Link = styled(TextContainer)`
    color: ${theme.colors['text-brand-primary']};
    font-weight: ${theme.fontWeights[400]};
`.withComponent('a');

export const ListContainer = styled.ul`
    display: grid;
    grid-gap: ${theme.space[16]};
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeights[18]};
    list-style: none;

    @media ${theme.mediaQueries.mdMin} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${theme.mediaQueries.lgMin} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const SectionHeading = styled(TextContainer)`
    margin: 0;
    padding-bottom: ${theme.space[16]};
    color: ${theme.colors['text-brand-primary']};
    font-size: ${theme.fontSizes[16]};
    font-weight: ${theme.fontWeights[700]};
`.withComponent('h3');

export const ShowAllButton = styled(Button)`
    height: ${theme.sizes[18]};
    padding: 0;
    border: none;
    background: transparent;
    color: ${theme.colors['text-brand-primary']};
    font-size: ${theme.fontSizes[14]};
    font-weight: ${theme.fontWeights[400]};
`;
