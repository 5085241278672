import type { DropdownProps, OptionType } from '@domains/shared/components/Dropdown/types';
import ChevronDown from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronDown';
import Tick from '@nexus/lib-react/dist/core/Icon/icons/default/Tick';
import type { DropdownOption } from '@type/search/dropdownOption';
import type { ForwardedRef, JSX, RefAttributes } from 'react';
import { forwardRef } from 'react';
import type { GroupBase, Props as ReactSelectProps } from 'react-select';
import type Select from 'react-select/base';

import { Dropdown } from './Dropdown';
import { NexusStyledOption } from './NexusDropdown.theme';

const NexusDropdownBase = <
    Option extends OptionType = DropdownOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: ReactSelectProps<Option, IsMulti, Group> & DropdownProps<Option>,
    ref: ForwardedRef<Select<Option, IsMulti, Group>>,
): JSX.Element => {
    return (
        <Dropdown
            {...props}
            ref={ref}
            components={{
                DropdownIndicator: (): JSX.Element | null => {
                    return <ChevronDown />;
                },
                Option: ({ children, isSelected, innerProps, isFocused }): JSX.Element | null => {
                    return (
                        <NexusStyledOption
                            isPrimary={props.variant === 'primary'}
                            isFocused={isFocused}
                            {...innerProps}
                        >
                            {children} {isSelected ? <Tick /> : null}
                        </NexusStyledOption>
                    );
                },
            }}
            shouldUseNexusTheme
        />
    );
};

export const NexusDropdown = forwardRef(NexusDropdownBase) as <
    Option extends OptionType = DropdownOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    render: ReactSelectProps<Option, IsMulti, Group> &
        DropdownProps<Option> &
        RefAttributes<Select<Option, IsMulti, Group>>,
) => JSX.Element;
