import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Container = styled.div<{ rows: number }>`
    display: grid;
    grid-column-gap: ${theme.space[8]};
    grid-template-columns: ${({ rows }): CSSProperties['gridTemplateColumns'] => `repeat(${rows}, 1fr)`};
`;

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.space[6]} ${theme.space[10]};
    border: 1px solid ${theme.colors['borders-global-secondary']};
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-primary']};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeights[18]};
    white-space: nowrap;
    gap: ${theme.space[8]};
`;
