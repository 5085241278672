import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import type { StyledComponent } from '@emotion/styled';
import type { ComponentProps, Dispatch, FC, JSX, KeyboardEvent, MouseEvent, SetStateAction } from 'react';
import { memo, startTransition, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { BaseDetails, BaseSummary } from './Collapsible.theme';
import type { Props, SummaryProps } from './props';
import type { State } from './state';
import { WatchContentLoad } from './WatchContentLoad';

interface Decorators {
    DetailsComponent?: StyledComponent<{ state: State }, ComponentProps<'details'>>;
    SummaryComponent?: StyledComponent<SummaryProps, ComponentProps<'details'>> | FC<SummaryProps>;
}

const RestartContentOnClientBase: FC<{
    setState: Dispatch<SetStateAction<State>>;
    shouldRenderContentOnSSR: boolean;
}> = ({ shouldRenderContentOnSSR, setState }) => {
    // Moved out of the main flow to proceed on client side before content
    useLayoutEffect(() => {
        //for SEO purposes, expanded content must be loaded on the SSR side so that it can be seen in the page source, but shouldn't be visible to users by default
        if (shouldRenderContentOnSSR) {
            // update state
            setState('closed');
        }
    }, [setState, shouldRenderContentOnSSR]);

    return null;
};

const RestartContentOnClient = memo(RestartContentOnClientBase);

export const Collapsible: FC<Props & Decorators> = ({
    animationDurationInMs = 300,
    children,
    testId = 'summary',
    dataCy,
    className,
    DetailsComponent = BaseDetails,
    onChange,
    shouldAnimate = true,
    isOpenByDefault = false,
    summary,
    SummaryComponent = BaseSummary,
    shouldCloseOnClickOutside = false,
    shouldRenderContentOnSSR = false,
}): JSX.Element => {
    const detailsRef = useRef<HTMLDetailsElement>(null);
    const detailsAnimationRef = useRef<Animation | null>(null);
    const isAnimationSupportedRef = useRef(false);
    const defaultStateOnClose = shouldRenderContentOnSSR ? 'seoOpen' : 'closed';
    const [state, setState] = useState<State>(isOpenByDefault ? 'opened' : defaultStateOnClose);
    const [isContentReady, setIsContentReady] = useState(isOpenByDefault);

    useEffect(() => {
        isAnimationSupportedRef.current = 'animate' in document.body;
    }, []);

    const onSummaryClick = (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>): void => {
        const isOpened = state === 'open' || state === 'opened';
        let newState: State = isOpened ? 'closed' : 'opened'; // Without animation

        if (shouldAnimate && isAnimationSupportedRef.current) {
            event.preventDefault();
            newState = isOpened ? 'close' : 'open'; // With animations
        }
        setState(newState);
        onChange?.(isOpened, newState, event);
    };

    const onSummaryKeyDown = (event: KeyboardEvent<HTMLElement>): void => {
        if (event.key === ' ') {
            event.preventDefault();
            onSummaryClick(event);
        }
    };

    useEffect(() => {
        const { current: detailsElement } = detailsRef;

        if (!shouldAnimate || !isAnimationSupportedRef.current || !detailsElement) {
            return;
        }
        if (state === 'open' && !detailsElement.open) {
            detailsElement.style.height = `${detailsElement.offsetHeight}px`;
            detailsElement.open = true;
        } else if ((state === 'open' && isContentReady) || state === 'close') {
            const detailsHeight = `${detailsElement.offsetHeight}px`;
            const summaryHeight = `${(detailsElement.firstElementChild as HTMLElement).offsetHeight}px`;
            let animationEasing = state === 'open' ? 'ease-in' : 'ease-out';

            if (detailsAnimationRef.current) {
                if (state === 'open') {
                    animationEasing = 'linear';
                }
                detailsAnimationRef.current.cancel();
            }
            const animation = detailsElement.animate(
                {
                    height:
                        state === 'open'
                            ? [detailsHeight, `${detailsElement.scrollHeight}px`]
                            : [detailsHeight, summaryHeight],
                },
                { duration: animationDurationInMs, easing: animationEasing },
            );

            animation.onfinish = (): void => {
                detailsAnimationRef.current = null;

                if (state === 'close') {
                    // keep the element height (after animation) until the overflow state is reset (the state is updated) - avoid blinking effect
                    detailsElement.style.height = summaryHeight;
                }

                startTransition(() => {
                    setState(state === 'open' ? 'opened' : 'closed');
                });
            };
            detailsElement.style.overflow = 'hidden';
            detailsElement.style.height = '';
            detailsAnimationRef.current = animation;
        } else if (state === 'opened' || state === 'closed') {
            detailsElement.open = state === 'opened';
            // reset styles
            detailsElement.style.overflow = 'auto';
            detailsElement.style.height = '';
        }
    }, [animationDurationInMs, isContentReady, shouldAnimate, state]);

    const onClickOutside = useCallback(() => {
        setState('close');
    }, []);

    // Watch the click outside only for the open element
    const shouldCloseOnClickOutsideWithCurrentState = shouldCloseOnClickOutside && state !== 'closed';

    useClickOutside(detailsRef, onClickOutside, shouldCloseOnClickOutsideWithCurrentState);

    return (
        <DetailsComponent className={className} open={isOpenByDefault} ref={detailsRef} state={state}>
            {typeof window === 'undefined' ? null : (
                <RestartContentOnClient shouldRenderContentOnSSR={shouldRenderContentOnSSR} setState={setState} />
            )}
            <SummaryComponent
                data-testid={testId}
                data-cy={dataCy}
                onClick={onSummaryClick}
                onKeyDown={onSummaryKeyDown}
                state={state}
                animationDurationInMs={animationDurationInMs}
            >
                {summary}
            </SummaryComponent>
            {state === 'closed' ? null : (
                <WatchContentLoad setIsContentReady={setIsContentReady}>{children}</WatchContentLoad>
            )}
        </DetailsComponent>
    );
};
