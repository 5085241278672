import { updateQueryParams } from '@domains/search/helpers/updateQueryParams';
import type { SearchPageVariant } from '@type/search/pageVariant';
import { useRouter } from 'next/router';
import type { JSX, PropsWithChildren } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

type UpdateViewType = (
    newViewType: SearchPageVariant,
    /** Note: It's walkaround for updating view type with additional params. When set to `false` it will override them */
    shouldSkipParamUpdate?: boolean,
) => void;

export const ViewTypeContext = createContext<{ viewType: SearchPageVariant; updateViewType: UpdateViewType }>({
    viewType: 'listing',
    updateViewType: () => undefined,
});

interface Props extends PropsWithChildren {
    urlViewType: SearchPageVariant;
}

export const ViewTypeContextProvider = ({ children, urlViewType }: Props): JSX.Element => {
    const router = useRouter();
    const [viewType, setViewType] = useState<SearchPageVariant>(urlViewType);

    const updateViewType: UpdateViewType = useCallback(
        (newViewType, shouldSkipParamUpdate) => {
            setViewType(newViewType);

            if (!shouldSkipParamUpdate) {
                updateQueryParams({ viewType: `${newViewType}` }, router);
            }
        },
        [router],
    );

    const value = useMemo(() => ({ updateViewType, viewType }), [updateViewType, viewType]);

    return <ViewTypeContext.Provider value={value}>{children}</ViewTypeContext.Provider>;
};
