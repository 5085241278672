import type { IconProps } from '@nexus/lib-react/dist/core/Icon';
import type { FC, JSX, ReactNode } from 'react';

import { Container, StyledButton } from './SearchActionButtons.theme';

interface Action {
    icon: FC<IconProps>;
    text: string;
    suffix?: ReactNode;
    dataCy?: string;
    onClick: () => void;
}

interface Props {
    actions: Action[];
}

export const SearchActionButtons = ({ actions }: Props): JSX.Element => {
    return (
        <Container rows={actions.length}>
            {actions.map(({ text, icon: Icon, suffix, dataCy, onClick }) => (
                <StyledButton key={text} data-cy={dataCy} onClick={onClick}>
                    <Icon size={16} />
                    <span>{text}</span>
                    {suffix}
                </StyledButton>
            ))}
        </Container>
    );
};
