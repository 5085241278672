import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Content = styled.span<{ size: 'regular' | 'small'; direction: 'horizontal' | 'vertical' }>`
    display: inline-flex;
    flex-flow: ${({ direction }): CSSProperties['flexDirection'] => (direction === 'vertical' ? 'column' : 'row')} wrap;
    align-items: baseline;
    font-size: ${({ size, direction }): CSSProperties['fontSize'] => {
        if (size === 'small' || direction === 'vertical') {
            return theme.fontSizes.p1;
        }

        return theme.fontSizes.h4;
    }};
    font-weight: ${theme.fontWeights.bold};
    line-height: ${theme.lineHeights[1]};
    gap: ${theme.space[8]};
`;

export const ExtraPriceInfo = styled.span`
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes.p3};
    font-weight: ${theme.fontWeights.regular};
`;

export const OriginalPrice = styled(ExtraPriceInfo)`
    text-decoration: line-through;
`;
