// @FIXME: move helper usage places inside this widget and move it to /core directory
import { SEARCH_BOX_WIDGET_CONFIG } from '@config/widgets/searchBox/searchBoxWidgetConfig';
import { DEFAULT_ADS_NUMBER_PER_PAGE, PER_PAGE_OPTIONS } from '@domains/shared/consts/defaultPerPage';
import { parseParameter } from '@domains/shared/helpers/parseParameter';
import type { FieldsMetadata } from '@type/search/fields';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';

import type { FilteringQueryParams } from '../types/helpers';
import { processQueryParams } from './helpers/processQueryParams';
import type { ParamsScope } from './types/params';

const PARAM_DEFAULT_VALUES = {
    page: 1,
    limit: Number(DEFAULT_ADS_NUMBER_PER_PAGE),
    market: 'ALL',
} as const;

export const parseQueryParamsToSearchingFilters = ({
    fieldsMetadataExperimentsVariants,
    queryParams,
    paramsScope,
    shouldFilterOutEstateAndTransaction = true,
}: {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    queryParams: Record<string, unknown>;
    /**
     * Used to limit possible parameters values (e.g. plotType has a different values per category).
     * Providing this params scope you limit the possibilities. If not provided all field values from all categories are allowed
     */
    paramsScope?: ParamsScope;
    shouldFilterOutEstateAndTransaction?: boolean;
}): FilteringQueryParams => {
    const { fieldsMetadata } = SEARCH_BOX_WIDGET_CONFIG;
    const newParams = new Map<string, unknown>();

    processQueryParams(
        newParams,
        queryParams,
        paramsScope,
        fieldsMetadata,
        fieldsMetadataExperimentsVariants,
        shouldFilterOutEstateAndTransaction,
    );

    handleRangeFields(fieldsMetadata, newParams);
    handleLocations(queryParams, newParams);
    handlePagination(newParams);
    handleIsPromoted(newParams);

    removeRedundantParams(newParams);

    return { ...PARAM_DEFAULT_VALUES, ...Object.fromEntries(newParams) };
};

const handleIsPromoted = (newParams: Map<string, unknown>): void => {
    if (typeof newParams.get('isPromoted') !== 'boolean') {
        newParams.delete('isPromoted');
    }
};

const handleRangeFields = (fieldsMetadata: FieldsMetadata, newParams: Map<string, unknown>): void => {
    const rangeFields = Object.entries(fieldsMetadata).filter(
        ([, metadataSets]) => metadataSets.default.fieldType === 'range',
    );

    // Validate if ranges are correct
    for (const [name] of rangeFields) {
        const min = `${name}Min`;
        const max = `${name}Max`;

        if (!(newParams.has(min) && newParams.has(max))) {
            continue;
        }

        if (Number(newParams.get(min)) > Number(newParams.get(max))) {
            newParams.delete(max);
        }
    }
};

const handleLocations = (queryParams: Record<string, unknown>, newParams: Map<string, unknown>): void => {
    if (typeof queryParams.locations === 'string') {
        const currentLocations = parseParameter(queryParams.locations);

        if (!newParams.has('locations') && currentLocations !== null) {
            newParams.set('locations', currentLocations);
        }
    }
};

const handlePagination = (newParams: Map<string, unknown>): void => {
    // Check the limit & reset the page if it is incorrect
    if (typeof newParams.get('page') !== 'number') {
        newParams.set('page', 1);
    }

    const limit = newParams.get('limit');

    if ((typeof limit !== 'number' && typeof limit !== 'string') || !PER_PAGE_OPTIONS.includes(limit.toString())) {
        // Reset the page only if the limit parameter was given
        if (newParams.has('limit')) {
            newParams.set('page', 1);
        }

        newParams.set('limit', Number(DEFAULT_ADS_NUMBER_PER_PAGE));
    }
};

const removeRedundantParams = (newParams: Map<string, unknown>): void => {
    // Delete params added by nextjs
    newParams.delete('lang');
    newParams.delete('searchingCriteria');

    // Delete params that shouldn't exist in filter attributes since
    newParams.delete('by');
    newParams.delete('direction');
};
