import { checkIsFieldMetadataAvailableForTransactionEstateType } from '@domains/shared/helpers/checkIsFieldMetadataAvailableForTransactionEstateType';
import { getFieldMetadataSetVariantKey } from '@domains/shared/helpers/getFieldMetadataSetVariantKey';
import type { FieldMetadata, FieldsMetadata } from '@type/search/fields';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { ParamsScope } from '@widgets/search/parseQueryParamsToSearchingFilters/types/params';

const NON_CONFIG_KEYS = new Set(['isPromoted', 'limit', 'page', 'sellerId']);

export const processQueryParams = (
    newParams: Map<string, unknown>,
    queryParams: Record<string, unknown>,
    paramsScope: ParamsScope | undefined,
    fieldsMetadata: FieldsMetadata,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
    shouldFilterOutEstateAndTransaction: boolean,
): void => {
    const filteredParams = createGenerallyFilteredParams(
        queryParams,
        paramsScope,
        fieldsMetadata,
        fieldsMetadataExperimentsVariants,
        shouldFilterOutEstateAndTransaction,
    );

    const groupedParams = createTypeGroupedParams(filteredParams);

    processStringParams(
        groupedParams.string,
        newParams,
        paramsScope,
        fieldsMetadata,
        fieldsMetadataExperimentsVariants,
    );
    processNumberParams(groupedParams.number, newParams, fieldsMetadata, fieldsMetadataExperimentsVariants);
    processBooleanParams(groupedParams.boolean, newParams, fieldsMetadata, fieldsMetadataExperimentsVariants);
    processArrayParams(groupedParams.array, newParams, paramsScope, fieldsMetadata, fieldsMetadataExperimentsVariants);
};

const createGenerallyFilteredParams = (
    queryParams: Record<string, unknown>,
    paramsScope: ParamsScope | undefined,
    fieldsMetadata: FieldsMetadata,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
    shouldFilterOutEstateAndTransaction: boolean,
): Record<string, unknown> => {
    return Object.fromEntries(
        Object.entries(queryParams)
            .filter(([name]) => {
                if (shouldFilterOutEstateAndTransaction && (name === 'estate' || name === 'transaction')) {
                    return false;
                }

                return true;
            })
            .filter(([name]) => {
                const isConfigField = !NON_CONFIG_KEYS.has(name);
                const metadata = getMetadata(fieldsMetadata, fieldsMetadataExperimentsVariants, name);

                if (isConfigField && !metadata) {
                    return false;
                }

                return true;
            })
            .filter(([name]) => {
                const metadata = getMetadata(fieldsMetadata, fieldsMetadataExperimentsVariants, name);
                const { estate, transaction } = paramsScope ?? {};

                if (!metadata || !estate || !transaction) {
                    return true;
                }

                const isAvailable = checkIsFieldMetadataAvailableForTransactionEstateType(
                    metadata,
                    estate,
                    transaction,
                );

                return isAvailable;
            }),
    );
};

type CreateTypeGroupedParamsReturnType = Record<'array', { name: string; value: string[] }[]> &
    Record<'boolean', { name: string; value: boolean }[]> &
    Record<'string', { name: string; value: string }[]> &
    Record<'number', { name: string; value: number }[]>;

const createTypeGroupedParams = (queryParams: Record<string, unknown>): CreateTypeGroupedParamsReturnType => {
    return Object.entries(queryParams).reduce<CreateTypeGroupedParamsReturnType>(
        (result, [name, value]) => {
            const isInvalidParamType =
                typeof value !== 'string' &&
                typeof value !== 'number' &&
                typeof value !== 'boolean' &&
                (typeof value !== 'object' || value === null);

            if (isInvalidParamType) {
                return result;
            }

            if (typeof value === 'boolean' || value === 'true' || value === 'false') {
                result.boolean.push({ name, value: parseBoolean(value) });

                return result;
            }

            if (Array.isArray(value)) {
                result.array.push({ name, value });

                return result;
            }

            if (typeof value === 'string' && checkIsStringifiedArray(value)) {
                result.array.push({ name, value: parseStringifiedArray(value) });

                return result;
            }

            const valueAsNumber = Number(value);
            const shouldIncludeAsNumber = !Number.isNaN(valueAsNumber) && !isEmptyString(value);

            if (shouldIncludeAsNumber) {
                result.number.push({ name, value: valueAsNumber });

                return result;
            }

            if (typeof value === 'string') {
                result.string.push({ name, value });

                return result;
            }

            return result;
        },
        {
            array: [],
            boolean: [],
            number: [],
            string: [],
        },
    );
};

const processStringParams = (
    params: { name: string; value: string }[],
    newParams: Map<string, unknown>,
    paramsScope: ParamsScope | undefined,
    fieldsMetadata: FieldsMetadata,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
): void => {
    for (const param of params) {
        if (isEmptyString(param.value)) {
            continue;
        }

        const metadata = getMetadata(fieldsMetadata, fieldsMetadataExperimentsVariants, param.name);

        // Strings shouldn't be assigned to following fields:
        // This guards us against wrong values in URL, e.g. priceMax=hehe
        const isIncorrectType = metadata && ['checkbox', 'range'].includes(metadata.fieldType);

        if (isIncorrectType) {
            continue;
        }

        if (metadata && 'options' in metadata.payload) {
            const possibleOptions = getPossibleOptions(metadata.payload.options, paramsScope);

            if (!possibleOptions.has(param.value)) {
                continue;
            }
        }

        newParams.set(param.name, param.value);
    }
};

const processNumberParams = (
    params: { name: string; value: number }[],
    newParams: Map<string, unknown>,
    fieldsMetadata: FieldsMetadata,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
): void => {
    const ignoredParams = new Set(['distanceRadius', 'daysSinceCreated', 'page', 'limit']);
    const paramsToStringify = new Set(['advertId', 'daysSinceCreated']);

    for (const param of params) {
        const metadata = getMetadata(fieldsMetadata, fieldsMetadataExperimentsVariants, param.name);

        // Numbers should only be assigned to following fields:
        // This guards us against wrong values in URL, e.g. ownerTypeSingleSelect=600
        const isIncorrectType = metadata && !(metadata.fieldType === 'input' || metadata.fieldType === 'range');
        const isIgnoredParam = ignoredParams.has(param.name);

        if (isIncorrectType && !isIgnoredParam) {
            continue;
        }

        if (paramsToStringify.has(param.name)) {
            newParams.set(param.name, param.value.toString());
            continue;
        }

        newParams.set(param.name, param.value);
    }
};

const processBooleanParams = (
    params: { name: string; value: boolean }[],
    newParams: Map<string, unknown>,
    fieldsMetadata: FieldsMetadata,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
): void => {
    for (const param of params) {
        const metadata = getMetadata(fieldsMetadata, fieldsMetadataExperimentsVariants, param.name);

        // Booleans should only be assigned to following fields:
        // This guards us against wrong values in URL, e.g. priceMin=true
        const isIncorrectType = metadata?.fieldType !== 'checkbox';
        const isIgnoredParam = param.name === 'isPromoted';

        if (isIncorrectType && !isIgnoredParam) {
            continue;
        }

        newParams.set(param.name, param.value);
    }
};

interface Options {
    name: string;
    value:
        | string[]
        | {
              order: number;
              value: string;
          }[];
}

export const processArrayParams = (
    params: Options[],
    newParams: Map<string, unknown>,
    paramsScope: ParamsScope | undefined,
    fieldsMetadata: FieldsMetadata,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
): void => {
    for (const param of params) {
        const metadata = getMetadata(fieldsMetadata, fieldsMetadataExperimentsVariants, param.name);

        // Arrays should only be assigned to fields with options:
        // This guards us against wrong values in URL, e.g. priceMin=%5BAIR_CONDITIONING%2CBALCONY%5D
        if (!metadata || !('options' in metadata.payload)) {
            continue;
        }

        const possibleOptions = getPossibleOptions(metadata.payload.options, paramsScope);
        const finalOptions = new Set();

        for (const option of param.value) {
            if (!option) {
                continue;
            }

            const value = typeof option === 'string' ? option : option.value;

            if (possibleOptions.has(value)) {
                finalOptions.add(value);
            }
        }

        if (finalOptions.size > 0) {
            newParams.set(param.name, [...finalOptions]);
        }
    }
};

const getMetadata = (
    fieldsMetadata: FieldsMetadata,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
    paramName: string,
): FieldMetadata | undefined => {
    const fieldMetadataSets = fieldsMetadata[paramName.replace(/(Min|Max)/, '')];
    const fieldMetadataSetVariantKey = fieldMetadataSets
        ? getFieldMetadataSetVariantKey({
              fieldMetadataSets,
              fieldsMetadataExperimentsVariants,
          })
        : 'default';

    const metadata = fieldMetadataSets?.[fieldMetadataSetVariantKey] ?? fieldMetadataSets?.default;

    return metadata;
};

const getPossibleOptions = (options: Record<string, string[]>, paramsScope: ParamsScope | undefined): Set<string> => {
    if (!paramsScope) {
        // Return values for all categories.
        return new Set(Object.values(options).flat());
    }

    const { estate, transaction } = paramsScope;
    const transactionVariant = `${estate}_${transaction}`;

    // Narrow categories and broad the result if not found.
    return new Set(options[transactionVariant] || options[estate] || options.DEFAULT || []);
};

const checkIsStringifiedArray = (value: string): boolean => {
    return value.startsWith('[') && value.endsWith(']');
};

const parseStringifiedArray = (value: string): string[] => {
    return value.slice(1, -1).split(',');
};

const isEmptyString = (queryParamValue: unknown): boolean => {
    return typeof queryParamValue === 'string' && queryParamValue.trim() === '';
};

const parseBoolean = (input: boolean | 'true' | 'false'): boolean => {
    if (typeof input === 'boolean') {
        return input;
    }

    return JSON.parse(input);
};
