import { ESTATE } from '@type/search/filters/estate';
import type { Media } from '@type/search/filters/media';
import type { SearchingFilters } from '@type/search/searchingFilters';

export const getParsedCustomCategories = (params: Partial<SearchingFilters>): Partial<SearchingFilters> => {
    const parameters = { ...params };

    if (parameters.estate === ESTATE.studioFlat) {
        parameters.estate = ESTATE.flat;
        parameters.roomsNumber = ['ONE'];
    }

    transformExtrasIntoParameters(parameters);

    // Note: Optional GQL variable only wants numbers, removing the param makes it `undefined` and the result is the same.
    // 'ANY' is a value of this parameter when rendering it as a radio group.
    if (parameters.daysSinceCreated === 'ANY') {
        delete parameters.daysSinceCreated;
    }

    if (parameters.roomsNumber?.includes('SIX_OR_MORE')) {
        const selectedRoomsNumber = parameters.roomsNumber.filter((value) => value !== 'SIX_OR_MORE');
        parameters.roomsNumber = [...selectedRoomsNumber, 'SIX', 'SEVEN', 'EIGHT', 'NINE', 'TEN', 'MORE'];
    }

    return parameters;
};

type SearchParameter =
    | 'hasDiscount'
    | 'hasMovie'
    | 'hasOpenDay'
    | 'hasPhotos'
    | 'hasRemoteServices'
    | 'hasWalkaround'
    | 'isBungalov'
    | 'isExclusiveOffer'
    | 'isForStudents'
    | 'isPetFriendly'
    | 'isPrivateOwner';

const EXTRAS_TO_REPLACE_AS_SEARCH_PARAMETER: Record<string, SearchParameter> = {
    /* eslint-disable @typescript-eslint/naming-convention -- extras are uppercase */
    HAS_DISCOUNT: 'hasDiscount',
    HAS_MOVIE: 'hasMovie',
    HAS_OPEN_DAY: 'hasOpenDay',
    HAS_PHOTOS: 'hasPhotos',
    HAS_REMOTE_SERVICES: 'hasRemoteServices',
    HAS_WALKAROUND: 'hasWalkaround',
    IS_BUNGALOV: 'isBungalov',
    IS_EXCLUSIVE_OFFER: 'isExclusiveOffer',
    IS_FOR_STUDENTS: 'isForStudents',
    IS_PET_FRIENDLY: 'isPetFriendly',
    IS_PRIVATE_OWNER: 'isPrivateOwner',
    /* eslint-enable @typescript-eslint/naming-convention */
};

const EXTRAS_TO_REPLACE_AS_MEDIA = new Set(['INTERNET', 'CABLE_TELEVISION']);

const transformExtrasIntoParameters = (parameters: Partial<SearchingFilters>): void => {
    if (!parameters.extras) {
        return;
    }

    parameters.extras = parameters.extras?.filter((extra) => {
        const filterName = EXTRAS_TO_REPLACE_AS_SEARCH_PARAMETER[extra];

        if (filterName) {
            parameters[filterName] = true;

            return false;
        }

        if (EXTRAS_TO_REPLACE_AS_MEDIA.has(extra)) {
            parameters.media = parameters.media ? ([...parameters.media, extra] as Media[]) : ([extra] as Media[]);

            return false;
        }

        return true;
    });
};
