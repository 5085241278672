import { getAdsCountFromPin } from '@domains/search/helpers/getAdsCountFromPin';
import type { Marker as MarkerProps, MarkerType } from '@domains/search/types/mapView';
import { MARKER_TYPE } from '@domains/search/types/mapView';
import { useUrlMapParams } from '@domains/shared/hooks/useUrlMapParams';
import { useTracking } from '@lib/tracking/useTracking';
import { useCallback } from 'react';

interface ExtraTrackingSet {
    zoom?: number;
    count?: number;
}

interface TrackViewChangeSet {
    touch_point_button: string;
    listing_format?: string;
    touch_point_page?: string;
}

interface MapTrackingMethods {
    trackViewChange: (trackingData: TrackViewChangeSet) => void;
    trackPinClick: (pin?: MarkerProps, extra?: ExtraTrackingSet) => void;
    trackPositionChange: (zoomLevel: number) => void;
    trackZoomChange: (zoomLevel: number, direction: 'in' | 'out') => void;
    trackSidebarDisplay: (pin: MarkerProps, extra?: ExtraTrackingSet) => void;
    trackGenericMapEvent: (eventName: string) => void;
}

const TRACKING_PIN_TYPES: Record<MarkerType, 'single' | 'grouped'> = {
    [MARKER_TYPE.single]: 'single',
    [MARKER_TYPE.cluster]: 'grouped',
    [MARKER_TYPE.processedCluster]: 'grouped',
};

const COMMON_OVERWRITES = {
    page_nb: null,
    page_count: null,
    result_count: null,
    results_per_page: null,
    ad_impressions: null,
};

const BY_GEO_JSON_OVERWRITES = {
    selected_locations_id: null,
};

export const useMapTracking = (): MapTrackingMethods => {
    const { hasBoundingBox } = useUrlMapParams();
    const { trackEvent } = useTracking();

    const trackPinClick = useCallback(
        (pin?: MarkerProps, extra?: ExtraTrackingSet): void => {
            const trackingSet = {
                ad_id: null,
                nb_ads: null,
                touch_point_button: null,
            };

            // eslint-disable-next-line unicorn/no-negated-condition -- needs to be refactored and covered with tests, otherwise SQ fails
            if (!pin) {
                Object.assign(trackingSet, {
                    nb_ads: extra?.count,
                    touch_point_button: 'map_grouped_precise',
                });
            } else {
                const adId = pin.type === MARKER_TYPE.single ? pin.value : null;
                const pinType = TRACKING_PIN_TYPES[pin.type];
                const locationType = pin.radiusInMeters && pin.radiusInMeters > 0 ? 'imprecise' : 'precise';

                let adsCount: number | number[] = 1;
                if (pin.type === MARKER_TYPE.cluster) {
                    adsCount = pin.value;
                } else if (pin.type === MARKER_TYPE.processedCluster && Array.isArray(pin.value)) {
                    adsCount = pin.value.length;
                }

                Object.assign(trackingSet, {
                    ad_id: adId,
                    nb_ads: adsCount,
                    touch_point_button: `map_${pinType}_${locationType}`,
                });
            }

            trackEvent('pin_click', {
                ...COMMON_OVERWRITES,
                ...(hasBoundingBox ? BY_GEO_JSON_OVERWRITES : {}),
                ...trackingSet,
                listing_format: 'map',
            });
        },
        [trackEvent, hasBoundingBox],
    );

    const trackSidebarDisplay = useCallback(
        (pin: MarkerProps, extra?: ExtraTrackingSet): void => {
            const zoom = extra?.zoom ?? null;
            const pinType = TRACKING_PIN_TYPES[pin.type];
            const locationType = pin.radiusInMeters && pin.radiusInMeters > 0 ? 'imprecise' : 'precise';
            const impressions = Array.isArray(pin.value) ? pin.value : null;
            const count = getAdsCountFromPin(pin);

            trackEvent('popup_listing', {
                ...COMMON_OVERWRITES,
                ...(hasBoundingBox ? BY_GEO_JSON_OVERWRITES : {}),
                nb_ads: count,
                zoom_level: zoom,
                listing_format: 'map',
                ad_impressions: impressions,
                touch_point_button: `map_${pinType}_${locationType}`,
            });
        },
        [trackEvent, hasBoundingBox],
    );

    const trackViewChange = useCallback(
        (trackingData: TrackViewChangeSet) => {
            trackEvent('listing_view_change', {
                ...COMMON_OVERWRITES,
                ...trackingData,
            });
        },
        [trackEvent],
    );

    const trackZoomChange = useCallback(
        (zoomLevel: number, direction: 'in' | 'out') => {
            trackEvent('zoom_map', {
                ...COMMON_OVERWRITES,
                ...(hasBoundingBox ? BY_GEO_JSON_OVERWRITES : {}),
                listing_format: 'map',
                zoom_level: zoomLevel,
                touch_point_button: `${direction}_${zoomLevel}`,
            });
        },
        [trackEvent, hasBoundingBox],
    );

    const trackPositionChange = useCallback(
        (zoomLevel: number) => {
            trackEvent('pan_map', {
                ...COMMON_OVERWRITES,
                ...(hasBoundingBox ? BY_GEO_JSON_OVERWRITES : {}),
                listing_format: 'map',
                zoom_level: zoomLevel,
                touch_point_button: zoomLevel,
            });
        },
        [trackEvent, hasBoundingBox],
    );

    const trackGenericMapEvent = useCallback(
        (eventName: string) => {
            trackEvent(eventName);
        },
        [trackEvent],
    );

    return {
        trackPinClick,
        trackViewChange,
        trackZoomChange,
        trackPositionChange,
        trackSidebarDisplay,
        trackGenericMapEvent,
    };
};
