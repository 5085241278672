import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { LocationLink } from '@type/location/locationLink';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import type { JSX } from 'react';
import { useState } from 'react';

import { createLocationLabel } from './helpers/createLocationLabel';
import { Container, Link, ListContainer, SectionHeading, ShowAllButton } from './LocationSeoLinks.theme';

interface Props {
    heading?: string;
    items: LocationLink[];
}

const MAX_VISIBLE_ITEMS = 15;

export const LocationSeoLinks = ({ heading, items }: Props): JSX.Element => {
    const [t] = useTranslations();
    const [shouldShowAllLinks, setShouldShowAllLinks] = useState(items.length <= MAX_VISIBLE_ITEMS);
    const { trackEvent } = useTracking();

    return (
        <Container>
            {heading ? <SectionHeading>{heading}</SectionHeading> : null}

            <ListContainer>
                {items.map(({ name, estate, transaction, location }, i) => {
                    const isHidden = !shouldShowAllLinks && i >= MAX_VISIBLE_ITEMS;

                    return (
                        <SeoLinkItem
                            key={`location-seo-link-${name}`}
                            name={name}
                            estate={estate}
                            transaction={transaction}
                            location={location}
                            isHidden={isHidden}
                            onClick={(): void => trackEvent('seo_link_click')}
                        />
                    );
                })}
                {shouldShowAllLinks ? null : (
                    <li>
                        <ShowAllButton variant="flat" onClick={(): void => setShouldShowAllLinks(true)}>
                            {t('frontend.shared.seoLinks.all')}
                        </ShowAllButton>
                    </li>
                )}
            </ListContainer>
        </Container>
    );
};

const SeoLinkItem = ({
    name,
    estate,
    transaction,
    location,
    onClick,
    isHidden,
}: {
    name: LocationLink['name'];
    estate: LocationLink['estate'];
    transaction: LocationLink['transaction'];
    location: LocationLink['location'];
    onClick: () => void;
    isHidden: boolean;
}): JSX.Element => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const locationLabel = createLocationLabel({ t, name, estate, transaction });

    return (
        <li hidden={isHidden}>
            <LocalLink
                passHref
                href={buildSearchUrl({
                    lang,
                    transaction,
                    estate,
                    location,
                })}
            >
                <Link onClick={onClick}>{locationLabel}</Link>
            </LocalLink>
        </li>
    );
};
