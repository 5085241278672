export const changeSearchQueryParams = (paramsToChange: { name: string; value: string | null }[]): void => {
    const searchParams = new URLSearchParams(window.location.search);

    for (const { name, value } of paramsToChange) {
        if (!value) {
            searchParams.delete(name);
        } else {
            searchParams.set(name, value);
        }
    }
    const { origin, pathname } = window.location;
    if (searchParams.toString().length === 0) {
        window.location.assign(`${origin + pathname}`);
    } else {
        window.location.assign(`${origin + pathname}?${searchParams.toString()}`);
    }
};

export const getSearchQueryParam = (name: string): string | null => {
    if (typeof window !== 'undefined') {
        const searchParams = new URLSearchParams(window.location.search);

        return searchParams.get(name);
    }

    return null;
};
