import type {
    AddToFavorites,
    AddToFavoritesPlacement,
} from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { ADD_TO_FAVORITES_BUTTON_VARIANT } from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { getAdvertCardTouchPointButton } from '@domains/shared/helpers/getAdvertCardTouchPointButton';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import type { ComponentProps, Dispatch, SetStateAction } from 'react';

export const createAddToFavoritesProps = ({
    id,
    isPromoted,
    isNearbySection,
    isRedirectToLoginModalOpen,
    setIsRedirectToLoginModalOpen,
    setSimilarAdsPromptId,
}: {
    id: number;
    isPromoted: boolean;
    isNearbySection: boolean;
    isRedirectToLoginModalOpen: boolean;
    setIsRedirectToLoginModalOpen: Dispatch<SetStateAction<boolean>>;
    setSimilarAdsPromptId: SetSimilarAdsPromptId | undefined;
}): ComponentProps<typeof AddToFavorites> => {
    return {
        id,
        variant: ADD_TO_FAVORITES_BUTTON_VARIANT.secondary,
        placement: 'listing' as AddToFavoritesPlacement,
        trackingData: {
            touch_point_button: getAdvertCardTouchPointButton({ isPromotedSection: isPromoted, isNearbySection }),
            ad_id: id,
        },
        isRedirectToLoginModalOpen,
        setIsRedirectToLoginModalOpen,
        setSimilarAdsPromptId,
    };
};
