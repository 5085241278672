import { getFormattedPrice } from '@domains/shared/helpers/getFormattedPrice';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Money } from '@type/pricing/money';
import type { JSX } from 'react';

import { getPrice } from './helpers/price';
import { Content, ExtraPriceInfo, OriginalPrice } from './Price.theme';

interface Props {
    total: Money | null;
    rent?: Money | null;
    beforeDiscount?: Money | null;
    size?: 'regular' | 'small';
    direction?: 'horizontal' | 'vertical';
    isEstimated?: boolean;
    isHidden?: boolean;
    isPerMeter?: boolean;
}

export const Price = ({
    total,
    rent,
    beforeDiscount,
    size = 'regular',
    direction = 'horizontal',
    isEstimated = false,
    isHidden = false,
    isPerMeter = false,
}: Props): JSX.Element => {
    const [t] = useTranslations();

    if (!total || isHidden) {
        return (
            <Content size={size} direction={direction}>
                {t('frontend.global.ad.ask-for-price')}
            </Content>
        );
    }

    const finalTotalPrice = getPrice(t, total, { isEstimated, isPerMeter });

    if (beforeDiscount) {
        return (
            <Content size={size} direction={direction}>
                {finalTotalPrice}
                <OriginalPrice>{getPrice(t, beforeDiscount, { isEstimated, isPerMeter })}</OriginalPrice>
            </Content>
        );
    }

    if (rent) {
        return (
            <Content size={size} direction={direction}>
                {finalTotalPrice}
                <ExtraPriceInfo>
                    + {t('frontend.search.advert-price.rent', { price: getFormattedPrice(rent) })}
                </ExtraPriceInfo>
            </Content>
        );
    }

    return (
        <Content size={size} direction={direction}>
            {finalTotalPrice}
        </Content>
    );
};
