import styled from '@emotion/styled';
import { SCREEN_READER_ONLY } from '@lib/styles/partials/helpers';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Container = styled.div`
    display: flex;
    gap: ${theme.space[8]};
    align-items: center;
`;

export const DescriptionList = styled.dl<{ isCompact?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    font-size: ${({ isCompact }): CSSProperties['fontSize'] => (isCompact ? theme.fontSizes.p3 : theme.fontSizes.p2)};

    dt {
        ${SCREEN_READER_ONLY}
    }

    dd {
        display: inline-flex;
        align-items: center;
        margin: 0;
        white-space: nowrap;
    }

    dd::after {
        content: '';
        display: inline-block;
        width: 3px;
        height: 3px;
        margin: 0 ${theme.space[6]};
        border-radius: 50%;
        background-color: currentColor;
    }

    dd:last-of-type::after {
        display: none;
    }
`;
