import { getPluralTranslationVariant } from '@domains/shared/helpers/getPluralTranslationVariant';
import {
    getRoomNumberByStringValue,
    getRoomNumberTypologyByStringValue,
} from '@domains/shared/helpers/getRoomNumberByTheString';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';

export const createRoomsNumberLabel = (
    t: Translator,
    lang: Locale,
    roomsNumber: RoomsNumber,
    variant: 'numerical' | 'typology' = 'numerical',
): string => {
    const rooms = Number(getRoomNumberByStringValue(roomsNumber));

    if (Number.isNaN(rooms)) {
        if (variant === 'typology') {
            return t('frontend.search.advert-specs-list.rooms-typology-more');
        }

        return t('frontend.search.advert-specs-list.rooms-more');
    }

    if (variant === 'typology') {
        return t('frontend.search.advert-specs-list.rooms-typology-many', {
            value: getRoomNumberTypologyByStringValue(roomsNumber),
        });
    }

    const translationVariant = getPluralTranslationVariant({
        lang,
        value: rooms,
        translation: {
            zero: 'frontend.search.advert-specs-list.rooms-zero',
            one: 'frontend.search.advert-specs-list.rooms-one',
            two: 'frontend.search.advert-specs-list.rooms-two',
            few: 'frontend.search.advert-specs-list.rooms-few',
            many: 'frontend.search.advert-specs-list.rooms-many',
            other: 'frontend.search.advert-specs-list.rooms-other',
        },
    });

    return t(translationVariant, { rooms: String(rooms) });
};
