import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { SEO_ESTATE } from '@type/search/filters/seoEstate';

export const getDescriptionParameter = (estate: string, t: Translator): string | undefined => {
    if (estate === SEO_ESTATE.villas) {
        return t('frontend.shared.seo.estate-label-villas').toLowerCase();
    }

    if (estate === SEO_ESTATE.penthouses) {
        return t('frontend.shared.seo.estate-label-penthouses').toLowerCase();
    }

    return undefined;
};
