import { SITE_CONFIG } from '@config/siteConfig';
import { createRoomsNumberLabel } from '@domains/shared/helpers/createRoomsNumberLabel';
import { getFormattedPrice } from '@domains/shared/helpers/getFormattedPrice';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Money } from '@type/pricing/money';
import type { Floors } from '@type/search/filters/floors';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import type { JSX } from 'react';

import { FLOORS_DEFINITION_TRANSLATIONS } from './constants/translations';
import { Container, DescriptionList } from './SpecsList.theme';

type Area = number | { from: number; to: number };

interface Props {
    area: Area | null;
    floor: Floors | null;
    rooms: RoomsNumber | null;
    pricePerMeter: Money | null;
    isCompact?: boolean;
}

export const SpecsList = ({ area, floor, rooms, pricePerMeter, isCompact = false }: Props): JSX.Element | null => {
    const [t] = useTranslations();

    if (!(area || floor || rooms || pricePerMeter)) {
        return null;
    }

    return (
        <Container data-testid="advert-card-specs-list">
            <DescriptionList isCompact={isCompact}>
                {rooms ? (
                    <>
                        <dt>{t('frontend.search.advert-specs-list.rooms-term')}</dt>
                        <RoomsDefinition value={rooms} />
                    </>
                ) : null}

                {area ? (
                    <>
                        <dt>{t('frontend.search.advert-specs-list.area-term')}</dt>
                        <AreaDefinition value={area} />
                    </>
                ) : null}

                {pricePerMeter ? (
                    <>
                        <dt>{t('frontend.search.advert-specs-list.price-per-meter-term')}</dt>
                        <PricePerMeterDefinition value={pricePerMeter} />
                    </>
                ) : null}

                {floor ? (
                    <>
                        <dt>{t('frontend.search.advert-specs-list.floor-term')}</dt>
                        <FloorsDefinition value={floor} />
                    </>
                ) : null}
            </DescriptionList>
        </Container>
    );
};

const RoomsDefinition = ({ value }: { value: RoomsNumber }): JSX.Element => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();

    const roomsNumberLabelVariant = SITE_CONFIG.shouldUseTypologyInsteadOfNumberOfRooms ? 'typology' : undefined;

    return <dd>{createRoomsNumberLabel(t, lang, value, roomsNumberLabelVariant)}</dd>;
};

const AreaDefinition = ({ value }: { value: Area }): JSX.Element => {
    const { area: unit } = SITE_CONFIG.defaultUnits;
    const [t] = useTranslations();

    if (typeof value === 'number') {
        return (
            <dd>
                {value} {unit}
            </dd>
        );
    }

    const areaFrom = `${t('frontend.search.item.from')} ${value.from} ${unit}`;
    const areaTo = `${t('frontend.search.item.to')} ${value.to} ${unit}`;

    return (
        <dd>
            {areaFrom} {areaTo}
        </dd>
    );
};

const PricePerMeterDefinition = ({ value }: { value: Money }): JSX.Element => {
    return (
        <dd>
            {getFormattedPrice(value)}/{SITE_CONFIG.defaultUnits.area}
        </dd>
    );
};

const FloorsDefinition = ({ value }: { value: Floors }): JSX.Element => {
    const [t] = useTranslations();

    const [key, variables] = FLOORS_DEFINITION_TRANSLATIONS[value];

    return <dd>{t(key, variables)}</dd>;
};
