import type { Address } from '@type/location/address';
import type { SearchAdsItemLocation } from '@type/search/location/searchAdsItemLocation';
import type { Location } from '@widgets/AdvertCard/Address';

export const extractLocationDetails = ({
    address,
    mapDetails,
    reverseGeocoding,
}: SearchAdsItemLocation): {
    address: Address;
    radius: number;
    locations: Location[];
} => {
    const radius = mapDetails?.radius ?? 0;
    const locations = reverseGeocoding?.locations ?? [];

    return { address, radius, locations };
};
