import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

const getBackgoundColor = (isFocused: boolean, isPrimary: boolean): CSSProperties['backgroundColor'] => {
    if (isFocused && isPrimary) {
        return theme.colors['background-global-secondary'];
    }

    if (isFocused && !isPrimary) {
        return theme.colors['background-global-primary'];
    }

    return 'inherit';
};

export const NexusStyledOption = styled.div<{ isFocused: boolean; isPrimary: boolean }>`
    display: flex;
    align-items: center;
    padding: ${theme.space[8]} ${theme.space[16]};
    background-color: ${({ isFocused, isPrimary }): CSSProperties['backgroundColor'] =>
        getBackgoundColor(isFocused, isPrimary)};
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.space[24]};
    cursor: pointer;
    gap: ${theme.space[6]};

    svg {
        margin-left: auto;
        color: ${theme.colors['background-brand-alternative']};
    }
`;
